import axios from 'axios';
import { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { NormalButton } from '../button.styles';
import { supabase, useAuth } from '../client';
import { ClearDropdown, ScrollableDropdownMenu } from '../container.styles';
import { Label, Notes, SliderValue, Sublabel } from '../text.styles';
import { v4 as uuidv4 } from 'uuid';
import { serverUrl } from '../config';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const RikuStableDiffusion = (props) => {

    const currentUser = useAuth();

    const { onDataFromChild,loadingStatus } = props;

    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [guidance, setGuidance] = useState(8.5);
    const [aspect, setAspect] = useState('Landscape');
    const [imageId, setImageId] = useState();


    const handleChange = (e) => {
        setPrompt(e.target.value);
      }
    
    
    function generateUUID() {
      return uuidv4();
    }
    
    async function handleFormSubmit(e) {
      e.preventDefault();
      setLoading(true);
      loadingStatus(true);
      try {
        // make the POST request and wait for the response
        let formData = { input: prompt, guidance: guidance, aspect: aspect.toLowerCase() };
        const res = await axios.post(`${serverUrl}/art/generateRikuImage`, formData);
        const imageData = res.data.image;

        // Generate a random ID and set it
        const imageId = generateUUID();
        setImageId(imageId);
        console.log(imageId);
        // Save the image to the gallery
        await saveImageToGallery(imageData, imageId);
        // Save the image URL and other metadata to the database
        await saveImageToDB(imageId);

        onDataFromChild(imageData);

        console.log(imageData);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        loadingStatus(false);
      }
    }
    
    async function saveImageToGallery(imageUrl, imageId) {
      try {
        // Fetch the image from the Express endpoint using axios
        const response = await axios.get(`${serverUrl}/art/fetch-image`, {
          params: {
            imageUrl: imageUrl
          },
          responseType: 'blob'
        });
        // Convert the image data to a Blob object
        const blob = response.data;
        console.log(imageId);
    
        // Upload the Blob object to supabase storage
        const { storageData, storageError } = await supabase
          .storage
          .from('gallery')
          .upload('public/'+imageId, blob);
        if (storageError) {
          console.error(storageError);
          throw new Error(storageError);
        } else {
          console.log(storageData);
          return storageData;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    
    
    async function getImageUrlFromStorage(imageId) {
      console.log(imageId)
      try {
        const urlData  = supabase
          .storage
          .from('gallery')
          .getPublicUrl('public/'+imageId);
        return urlData;
      } catch (error) {
        console.error(error);
      }
    }
    
      async function saveImageToDB(imageId) {
        // Get the public URL of the uploaded image
        const imageUrl = await getImageUrlFromStorage(imageId);
        console.log(imageUrl.data.publicUrl);
    
        // Save the image URL and other metadata to the database
        const insertData = {
          uuid: generateUUID(),
          user_id: currentUser.user.id,
          image_url: imageUrl.data.publicUrl,
          date_generated: new Date(),
          prompt: prompt,
          privacy: 'public',
          aspect: aspect.toLowerCase(),
          guidance: guidance,
        };
        const { data } = await supabase
          .from('images')
          .insert([insertData]);
        return data;
      } 

  return (
    <Form>
    <Form.Group className="mb-3">
      <Sublabel>What do you want AI to draw?</Sublabel>
      <Notes disabled={loading} as="textarea" onChange={handleChange} rows={3} />
    </Form.Group>
    <Sublabel>How much you want the AI to influence the image. The higher the more stylized.</Sublabel>
    <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
    <Label>0</Label>
    <Form.Range
      type="range"
      min={0}
      max={50}
      step={0.1}
      value={guidance}
      onChange={e => setGuidance(e.target.value)}
      disabled={loading}
      style={{ flexGrow: 1 }}
      data-tooltip-content={guidance}  
      data-tooltip-id="slider-tooltip" 
    />
    <Label>50</Label>
    <SliderValue>{guidance}</SliderValue>
  </div>
  <Tooltip id="slider-tooltip" />
  <Sublabel>Choose the size of the image.</Sublabel>
    <ClearDropdown className="mb-3" >
      <Dropdown.Toggle variant="success" id="dropdown-basic" className='mb-3' disabled={loading} >
       {aspect}
      </Dropdown.Toggle>

      <ScrollableDropdownMenu>
        <Dropdown.Item onClick={() => setAspect('Landscape')}>Landscape</Dropdown.Item>
        <Dropdown.Item onClick={() => setAspect('Square')}>Square</Dropdown.Item>
        <Dropdown.Item onClick={() => setAspect('Tall')}>Tall</Dropdown.Item>
    </ScrollableDropdownMenu>
    </ClearDropdown>
    <NormalButton hidden={prompt.length === 0 || loading} type="submit" onClick={handleFormSubmit} >DRAW</NormalButton>
    {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}
  </Form>
  )
}
