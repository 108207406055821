import { useState, useEffect } from 'react';
import axios from 'axios';
import { serverUrl } from '../config';

export const useVoices = ()=> {
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await axios.get(`${serverUrl}/listen/get-voices`, {
          headers: {
            accept: 'application/json',
          },
        });
        setVoices(response.data.voices);
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };

    fetchVoices();
  }, [serverUrl]);

  return voices;
};


