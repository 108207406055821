export const routeMetaData = {
    '/': {
      title: 'Home - Fictioneer',
      description: 'Welcome to Fictioneer, where you can generate articles, stories, and art using GPT-3'
    },
    '/story': {
      title: 'Story - Fictioneer',
      description: 'Generate Stories using GPT-3'
    },
    '/article': {
      title: 'Article - Fictioneer',
      description: 'Generate Articles using GPT-3'
    },
    '/art': {
        title: 'Art - Fictioneer',
        description: 'Generate Art using GPT-3'
    },
    '/dashboard': {
        title: 'Home - Fictioneer',
        description: 'Welcome to Fictioneer, where you can generate articles, stories, and art using GPT-3'
    },
    '/challenge': {
        title: 'Challenge - Fictioneer',
        description: 'Test your skill at guessing what prompt each art is generated with'
    },
    '/history': {
        title: 'History - Fictioneer',
        description: 'Find past generations of ar or text generated by Fictioneer'
    },
    '/listen': {
        title: 'Listen - Fictioneer',
        description: 'Listen to an AI read any text you give it.'
    },
    '/login': {
        title: 'Login - Fictioneer',
        description: 'Welcome to Fictioneer, where you can generate articles, stories, and art using GPT-3'
    },
    '/register': {
        title: 'Register - Fictioneer',
        description: 'Welcome to Fictioneer, where you can generate articles, stories, and art using GPT-3'
    },
    '/storyteller': {
        title: 'Storyteller - Fictioneer',
        description: 'Create a storytime video for your stories'
    },
               
  }