import React from 'react'
import {motion} from 'framer-motion';
import { ToolBGContainer } from '../components/container.styles';
import { Col, Container, Row } from 'react-bootstrap';
import { LogoText, SloganText } from '../components/text.styles';
import { useAuth } from '../components/client'
import { useNavigate } from 'react-router-dom';
import { Logout } from '../components/logout';
import { EditorBG } from '../assets';

const Editor = () => {

  const currentUser = useAuth();
  let navigate = useNavigate();

  return (
    <motion.div initial={{opacity:0}} animate={{ opacity:1 }} exit={{opacity:0}} transition={{ delay: .2 }}>
     <ToolBGContainer
      color1={"rgba(89,89,89,0.3)"}
      color2={"rgba(47,46,44,0.8)"}
      img={EditorBG} 
       >
      
      <Container>
      <Row>
        <Col>
          <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
          <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
        </Col>
      </Row>
      </Container>
      </ToolBGContainer>
    </motion.div>
  )
}

export default Editor