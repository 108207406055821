import { Form, Table } from 'react-bootstrap';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ProjectListCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 8px;
  /* max-width: 400px; */
  height: auto;
  background: linear-gradient(to bottom right, #1c817880, #02141f80);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  border: 3px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
`;


export const ProjectListButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  margin-right: 8px;
  background-color: #031a3d;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #00040a;
  }

  svg {
    font-size: 18px;
  }
`;

export const ProjectListDeleteButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  margin-right: 8px;
  background-color: #7b243a;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #a12f4c;
  }

  svg {
    font-size: 18px;
  }
`;

export const ProjectListTable = styled(Table)`
  background-color: #394B43;
  color: #c2c3c3 !important;
  border-radius: 28px;
  cursor: pointer;

  thead {
    background-color: #4D655D;
    color: #edf1f2;

  }

  tbody tr td {
    
    &:nth-of-type(even) {
      background-color: #4D655D;
      color: #bbbebf;
    }

    &:nth-of-type(odd) {
      background-color: #4D655D;
      color: #bbbebf;
    }

    &:hover {
      background-color: #506E61;
      color: #bbbebf;
    }
  }

  /* th, td {
    border: 1px solid #4D655D;
    color: #FFFFFF;
  } */
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 10px;
`;

export const CustomColorButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  color: ${({ textColor }) => textColor || '#000000'};
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || '#cccccc'};
    color: ${({ hoverTextColor }) => hoverTextColor || '#000000'};
  }

  &:focus {
    outline: none;
  }
`;

export const SwitchTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StorybookSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #104d4e;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #1f989a;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
`;


export const StorybookSlider = styled.input`
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(to bottom right, #1c8178, #02141f);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }
`;


export const StorybookModel = styled(Modal)`
  color: white;
  font-family: "Comfortaa",sans-serif;


  .modal-content {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding: 16px;
    margin: 8px;
    background: linear-gradient(to bottom right, #1c817880, #02141f80);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    .close svg {
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat; 
    }
  }
`;


export const AudioSelector = styled.div`
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 8px;
  border: 1px solid #416363;
  border-radius: 8px;
  background: linear-gradient(to bottom right, #416363, #5a8989);
  backdrop-filter: blur(10px);
  /* &:hover {
    border-color: #ffffff;
  } */
`;


export const CustomChooseFileButton = styled.button`
  font-size: 14px;
  color: #ffffff;
  background: #0c3f41;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  &:hover {
    background: #136467;
  }
`;

export const HiddenAudioInput = styled.input`
  display: none;
`;

export const AudioInputLabel = styled.label`
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 8px;
`;

export const AudioInput = styled.input`
  width: 100%;
  cursor: pointer;
`;

export const StorybookCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 8px;
  /* max-width: 450px; */
  /* height: 250px; */
  background: linear-gradient(to bottom right, #1c817880, #02141f80);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  border: 3px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
`;

export const StorybookPreviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 8px;
  background: linear-gradient(to bottom right, #1c817880, #02141f80);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  border: 3px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
  position: relative;
  overflow: hidden;
`;


export const StorybookLabel = styled.label`
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 8px;
`;

export const StorybookInput = styled.input`
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  margin-bottom: 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #020202;
  outline: none;

  ::placeholder {
    color: #010c1480;
  }

  &::selection {
    background-color: #385555;
    color: #83c7c7;
  }
`;


export const StorybookButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-family: "Comfortaa",sans-serif;
  margin-right: 8px;
  background-color: #447c7c;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #58a2a2;
  }

  svg {
    font-size: 18px;
  }
`;

export const StorybookAudio = styled.audio`
  margin-top: 8px;
`;

export const StoryBookMediaPreviewButton = styled.button`
  position: relative;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
`;

export const StoryBookMediaPreviewContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const StoryBookMediaPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StoryBookMediaPreviewPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #6c757d;
  font-size: 10px;
  text-align: center;
`;

export const StoryBookMediaPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;
