import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LogoText, SloganText } from './text.styles';
import { Line } from './container.styles';

function Header() {

    let navigate = useNavigate();

  return (
      <Col xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
        <SloganText className="pb-3" onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
      </Col>
  );
}

export default Header;
