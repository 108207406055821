import React from 'react'
import { ClearCard, ToolBGContainer } from '../components/container.styles'
import { Error404, LogoText, SloganText, Text404 } from '../components/text.styles'
import Bg from '../assets/error404.png'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
    let navigate = useNavigate();

    return (
        <ToolBGContainer
            color1={"rgba(29,39,56,0.4)"}
            color2={"rgba(36,43,57,0.9)"}
            img={Bg}>

            <Row>
                <Col lg={12}>
                    <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
                    <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
                </Col>
                <Col style={{"padding":"0","alignItems":"start","justifyItems":"center","justifyContent":"center","alignContent":"start","display":"grid"}}>
                        <Error404>404</Error404>
                        <Text404>The page you are looking for does not exist.</Text404>
                </Col>
            </Row>

        </ToolBGContainer>
    )
}
