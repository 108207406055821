import React, { useState } from 'react';
import { Form } from 'react-bootstrap'
import { ClearCard } from '../container.styles';
import { NormalButton } from '../button.styles';
import axios from 'axios';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useSaveText } from '../SaveText';
import { serverUrl } from '../config';
import { Sublabel } from '../text.styles';


export const FictionalStoryGen = (props) => {
    const { onDataFromChild } = props;
    const [loading, setLoading] = useState(false);
    const [story, setStory] = useState('');
    const { saveText } = useSaveText();

    async function handleFormSubmit(e) {
        console.log('clicked!');
        e.preventDefault();
        let formData = { input: story };
        console.log(formData);
        setLoading(true);
        try {
          // make the POST request and wait for the response
          const res = await axios.post(`${serverUrl}/story/getStory`, formData);

          // get the data from the response
          const data = res.data;

          // save the generated text
          const result = await saveText(data[0].Outputs[0].Generated, 'Fictional');
          console.log(result);

          // pass the generated article to the parent component
          onDataFromChild(data[0].Outputs[0].Generated);
        } catch (error) {
          // handle any errors that occurred during the request
          console.error(error);
        } finally {
          // set loading to false when the request is complete
          setLoading(false);
        }
      }

    const handleChange = (e) => {
        setStory(e.target.value);
    }

    return (
        <ClearCard>
            <h3>FICTIONAL STORY</h3>
            <Form>
                    <Sublabel>What are some details about the story?</Sublabel>
                    <Form.Control className="form-control input mb-3" type="text" onChange={handleChange} rows={1}  />
            </Form>
            <NormalButton hidden={loading} type="submit" onClick={handleFormSubmit} >Write</NormalButton>
            {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}
        </ClearCard>
    )
}
