import { createClient } from '@supabase/supabase-js'
import React, { useContext, useState, useEffect } from 'react'
import { Loading } from '../pages'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

const AuthContext = React.createContext()

export function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
  
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setLoading(false);
    };
  
    useEffect(() => {
      getSession();
  
      // Listen for changes on auth state (logged in, signed out, etc.)
      const { data: { subscription } } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setUser(session?.user ?? null);
          setLoading(false);
        }
      );
  
      return () => {
        // Unsubscribe from the onAuthStateChange subscription when the component is unmounted.
        subscription?.unsubscribe();
      };
    }, []);
  
    // Will be passed down to Signup, Login and Dashboard components
    const value = {
      signUp: (data) => supabase.auth.signUp(data),
      signIn: (data) => supabase.auth.signInWithPassword(data),
      signOut: () => supabase.auth.signOut(),
      user,
    };
  
    return (
      <AuthContext.Provider value={value}>
        {loading ? <Loading /> : children}
      </AuthContext.Provider>
    );
  }

  export function useAuth() {
    return useContext(AuthContext)
  }
