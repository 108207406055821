import React, { useState } from 'react';
import {Form} from 'react-bootstrap'
import { ClearCard } from '../container.styles';
import { NormalButton } from '../button.styles';
import axios from 'axios';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useSaveText } from '../SaveText';
import { serverUrl } from '../config';
import { Sublabel } from '../text.styles';


export const ParagraphGen = (props) => {
    const { onDataFromChild } = props;
    const [topic, setTopic] = useState("");
    const [loading, setLoading] = useState(false);
    const { saveText } = useSaveText();


    async function handleFormSubmit(e) {
      e.preventDefault();
      let formData = { topic: topic};
      setLoading(true);
      try {
        // make the POST request and wait for the response
        const res = await axios.post(`${serverUrl}/article/getParagraph`, formData);
    
        // get the data from the response
        const data = res.data;

        // save the generated text
        const result = await saveText(data[0].Outputs[0].Generated, 'Paragraph');
        console.log(result);

        // pass the generated article to the parent component
        onDataFromChild(data[0].Outputs[0].Generated);
      } catch (error) {
        // handle any errors that occurred during the request
        console.error(error);
      } finally {
        // set loading to false when the request is complete
        setLoading(false);
      }
    }
  
    const handleTopic = (e) => {
      setTopic(e.target.value);
    }

  
    


  return (
    <ClearCard>
      <h3>PARAGRAPH</h3>
      <Form>
        <Sublabel>What is the article about?</Sublabel>
        <Form.Control className="form-control input mb-3" type="text" onChange={handleTopic} rows={1} />

      </Form>
      <NormalButton hidden={loading} type="submit" onClick={handleFormSubmit} >Write</NormalButton>
      {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}
    </ClearCard>
  )
}
