import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { ClearCard, Line, PopUp, ToolBGContainer } from '../components/container.styles';
import { Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { LogoText, SloganText } from '../components/text.styles';
import { supabase, useAuth } from '../components/client'
import { useNavigate } from 'react-router-dom';
import { Logout } from '../components/logout';
import { LoginBG } from '../assets';
import { useForm } from 'react-hook-form';
import { NormalButton } from '../components/button.styles';

// Finish adding the update user and add popup for errors and success messages. Also redirect back to login when succes.

const ResetPassword = () => {

    const currentUser = useAuth();
    let navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm();


    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [showSuccess, setSuccessShow] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleErrorShow = () => setError(true);
    const handleErrorClose = () => setError(false);
    const handleSuccessClose = () => setSuccessShow(false);
    const handleSuccessShow = () => setSuccessShow(true);

    const onSubmit = async data => {
        try {
            const { error } = await supabase.auth.updateUser({
                password: data.newPw,
            });

            if (error) throw error;

            setSuccessMessage("Password successfully updated");
            handleSuccessShow();
        } catch (error) {
            console.log(error);
            setError(error);
            handleErrorShow();
        }
    };

    const validatePassword = value => {
        if (!value.length >=6 ){
          return setError("Password must be 6 characters or more");
        }
    };
      

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
            <ToolBGContainer
                color1={"rgba(89,89,89,0.3)"}
                color2={"rgba(47,46,44,0.8)"}
                img={LoginBG}
            >
                <Container className="contact-content debug-border">
                    
                    <Row>
                        <Col>
                            <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
                            <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
                        </Col>
                        <Line className="mt-3" />
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            <ClearCard>
                                <h3 className="mt-5 mb-4 text-light font-weight-bold">Reset Password</h3>
                                <h5 className="mb-4 text-light">Enter your new password and hit reset. We'll take care of the rest.</h5>
                                <Form onSubmit={handleSubmit(onSubmit)}>

                                   <InputGroup className="mb-3">
                                        <Form.Control
                                            {...register("newPw", {
                                                required: true,
                                                validate: validatePassword
                                            })}
                                            placeholder="Enter your new password"
                                        />
                                    </InputGroup>
                                    {errors.newPw && <p>{error}</p>}

                                    <NormalButton type="submit">Reset</NormalButton>
                                </Form>


                                <PopUp show={error} onHide={handleErrorClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Password Reset</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body> {error}</Modal.Body>
                                    <Modal.Footer>
                                        <NormalButton variant="secondary" onClick={handleErrorClose}>
                                            Close
                                        </NormalButton>
                                    </Modal.Footer>
                                </PopUp>

                                <PopUp show={showSuccess} onHide={handleSuccessClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Password Reset</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>{successMessage}</Modal.Body>
                                    <Modal.Footer>
                                        <NormalButton variant="secondary" onClick={handleSuccessClose}>
                                            Close
                                        </NormalButton>
                                    </Modal.Footer>
                                </PopUp>
                            </ClearCard>
                        </Col>
                    </Row>



                </Container>
            </ToolBGContainer>
        </motion.div>
    )
}

export default ResetPassword