
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import React from 'react'

<style>
  @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Jost:wght@300&display=swap');
</style>


export const Flicker = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.7; }
  100% { opacity:1; }
`

export const LogoText = styled.h1`
 
 font-weight: 700 !important;
    color: #fff;
    font-size: 2.5rem !important;
    margin-top: 2rem !important;
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    text-shadow:
      0 0 10px #fff,
      0 0 22px #fff,
      0 0 42px #124266,
      0 0 82px #093D65,
      0 0 92px #093D65,
      0 0 102px #093D65,
      0 0 151px #093D65;
    font-family: 'Comfortaa', sans-serif;
    animation-name: ${Flicker};
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
    cursor: pointer;
    
`

export const SloganText = styled.h3`
    margin-top: 0rem !important;
    text-align: bottom;
    color: #fff;
    font-size: 1.0rem !important;
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 42px #124266,
      0 0 82px #093D65,
      0 0 92px #093D65,
      0 0 102px #093D65,
      0 0 151px #093D65;
    font-family: 'Comfortaa', sans-serif;
    cursor: pointer;

`

export const Title = styled.h4`
    text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 1.5rem !important;
    justify-content: center;
    display: grid;
    font-family: 'Comfortaa', sans-serif;
`





export const Notes = styled.textarea`
            border-radius: 12px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
            line-height: 31px;
            margin: 5px;
            width:95%;
            color: black;
            opacity: 0.8;
            padding: 8px;

`
export const MyLink = styled(Link)`
    text-decoration: none;
    color: #fff;
    margin: 10px;
    font-family: 'Comfortaa', sans-serif;
    &:hover {
        color: #b2b2b2;
        text-decoration: none;
    }
`
export const RedirectLabel = styled.h6`
  color:#b2b2b2;
  font-family: 'Comfortaa', sans-serif;
  margin-top: 1.5rem ;
`

export const Error404 = styled.h1`
  color:#b2b2b2;
  font-family: 'Comfortaa', sans-serif;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.81);
  font-weight: 900;
  font-size: 700%;
`

export const Text404 = styled.h1`
  color:#b2b2b2;
  font-family: 'Comfortaa', sans-serif;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.81);
  text-align: center;
  font-weight: 600;
  font-size: 200%;
`

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;


export const Label = styled.label`
  width: 50px;
  text-align: center;
`;

export const SliderValue = styled.span`
  background-color: #00000070;
  border-radius: 5px;
  padding: 5px;
  margin: 3px;
  border: 1px solid #000000;
`;


export const TabTitle = styled.h3`
  background-color: transparent;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-style: 0px solid;
  border-radius: 12px;
  padding: 1rem;
`;


export const TabSubtitle = styled.h6`
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
`;


export const Sublabel = styled.p`
  color: rgba(255, 255, 255, 0.81);
  font-size: small;
  margin: 0px;
  margin-bottom: 3px;
`;