import React from 'react';
import { Art, Article, Ask, Chat, Dashboard, Editor, Login, Register, Story, Loading, Challenge, History, Listen, ResetPassword, StoryTeller, ProjectList } from '../pages';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useAuth } from './client';
import { AuthProvider } from './client';
import { Navigate, Outlet } from 'react-router-dom';
import { NotFound } from '../pages/NotFound';
import { SaveTextProvider } from './SaveText';
import { routeMetaData } from './Metadata';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavSidebar from './Sidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import Storybook from '../pages/Storybook';

const ProtectedRoutes = () => {
  const auth = useAuth();

  return (
    <>
      {auth.user ? (
        <>
          <NavSidebar />
          <Outlet />
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

const PublicRoutes = () => {
  const auth = useAuth();
  return (
    <>
      {auth.user ? (
        <Navigate to="/" />
      ) : (
        <>
          <Outlet />
        </>
      )}
    </>
  );
};





const AnimatedRoutes = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const metaData = routeMetaData[currentRoute] || {};
  const { title, description } = metaData;

  return (
    <HelmetProvider>
      <AuthProvider>
        <ProSidebarProvider>
          <SaveTextProvider>
            <AnimatePresence>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
              </Helmet>
              <Routes location={location} key={location.pathname}>
                <Route element={<ProtectedRoutes />}>
                  <Route path="" exact element={<Dashboard />} />
                  <Route path="/dashboard" exact element={<Dashboard />} />
                  <Route path="/story" exact element={<Story />} />
                  <Route path="/article" exact element={<Article />} />
                  <Route path="/art" exact element={<Art />} />
                  <Route path="/ask" exact element={<Ask />} />
                  <Route path="/chat" exact element={<Chat />} />
                  <Route path="/editor" exact element={<Editor />} />
                  <Route path="/challenge" exact element={<Challenge />} />
                  <Route path="/history" exact element={<History />} />
                  <Route path="/listen" exact element={<Listen />} />
                  <Route path="/storyteller" element={<ProjectList />} />
                  <Route path="/storyteller/:projectId" element={<Storybook />} />
                  <Route path="/reset-password" exact element={<ResetPassword />} />
                </Route>

                <Route element={<PublicRoutes />}>
                  <Route path="/register" exact element={<Register />} />
                  <Route path="/login" exact element={<Login />} />
                  <Route path="" exact element={<Login />} />
                </Route>
                <Route path="/loading" exact element={<Loading />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </AnimatePresence>
          </SaveTextProvider>
        </ProSidebarProvider>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default AnimatedRoutes