import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

export const ListenTabs = styled(Tabs)`
    button.nav-link {
        color: #FFFFFF;
        border-radius: 28px;
        border: 2px solid rgba(255, 255, 255, 1);
        margin: 5px;
        background:#0e2637;

        :hover{
            background-color: rgba(255,255,255,0.4);
        }
    }

    div#popover-61b8b4514268666c126bb2ff.popover.bs-popover-end{
        background-color: rgba(255,255,255,0.4);           
    }

    button.nav-link.active {
        backdrop-filter: blur(10px) saturate(150%);
        border-radius: 12px;
        color: #fff;
        border-radius: 28px;
        background: #348ecf !important;
        opacity: 1 !important;
        font-weight: bold;
        border: 2px solid rgba(255, 255, 255, 1);
        margin: 5px;

        :hover{
            background-color: #4c4c4c;
        }
    }

    div.tab-pane.active {
  transition: opacity 0.15s linear 0s;
  display: grid !important;
  grid-auto-flow: column !important;
  justify-items: center !important;
}

    
`;

export const ListenTab = styled(Tab)`

div.tab-pane.active {
  transition: opacity 0.15s linear 0s;
  display: grid !important;
  grid-auto-flow: column !important;
  justify-items: center !important;
}

`



export const PopoverContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .Popover{
  text-align: center;
}
`

export const VoiceInfo = styled.div`
  display: flex ;
  flex-direction: row ;
  justify-content: center ;
  border-radius: 50px ;
  align-items: center ;
  background-color: #f1f3f4;
  padding: 2%;
  color: black;

  
  h6{
    margin: auto;
    padding-right: 10px;
  }

  audio {
    width: 100px;
  }
`

export const ListenOutput = styled.div`
  display: flex ;
  flex-direction: row ;
  justify-content: center ;
  border-radius: 50px ;
  align-items: center ;
  background: #00916d;
  padding: 2%;
  color: white;
  margin-top: 10px;
  backdrop-filter: blur(15px) saturate(110%) !important;
  border: 3px solid rgba(255, 255, 255, 0.5);

  h6{
    margin: auto;
    padding-right: 10px;
    font-weight: bold;
  }

  audio {
    width: 50%;
  }
`