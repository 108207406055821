import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LogoText, Notes, SloganText, TabTitle } from '../components/text.styles';
import { ClearCard, Line, MyTab, MyTabs, ToolBGContainer } from '../components/container.styles';
import { motion } from 'framer-motion';
import { FullArticleGen } from '../components/article-tools/FullArticleGen';
import { IntroGen } from '../components/article-tools/IntroGen';
import { ParagraphGen } from '../components/article-tools/ParagraphGen';
import { ConclusionGen } from '../components/article-tools/ConclusionGen';
import { ArticleBG } from '../assets';

const Article = () => {
  let navigate = useNavigate();

  const [dataFromChild, setDataFromChild] = useState("");

  function handleDataFromChild(data) {
    console.log(data);
    // do something with the data passed from the child component
    setDataFromChild(data);
  }


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>

      <ToolBGContainer
        color1={"rgba(89,89,89,0.3)"}
        color2={"rgba(47,46,44,0.8)"}
        img={ArticleBG}
      >
        <Container>


          <Row className="mb-5">
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
              <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
            </Col>
            <Line className="mt-3" />
          </Row>
          <Row>
            <Col lg={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
              <TabTitle>
                ARTICLE TOOLS
              </TabTitle>
              <MyTabs
                defaultActiveKey="intro"
                id="tool-tabs"
                className="mb-3"
                variant="pills"
              >
                <MyTab eventKey="full" title="Full Article">
                  <FullArticleGen onDataFromChild={handleDataFromChild} />
                </MyTab>
                <MyTab eventKey="intro" title="Intro">
                  <IntroGen onDataFromChild={handleDataFromChild} />
                </MyTab>
                <MyTab eventKey="paragraph" title="Paragraph">
                  <ParagraphGen onDataFromChild={handleDataFromChild} />
                </MyTab>
                <MyTab eventKey="conclusion" title="Conclusion">
                  <ConclusionGen onDataFromChild={handleDataFromChild} />
                </MyTab>
              </MyTabs>
            </Col>
            <Col lg={6} style={{ display: 'grid', alignContent: 'center' }}>
              <Col lg={12}>
                <ClearCard>
                  <h3>Output:</h3>
                  <Notes
                    readOnly
                    rows={4}
                    value={dataFromChild}>
                  </Notes>
                </ClearCard>
              </Col>
            </Col>
          </Row>
        </Container>
      </ToolBGContainer>
    </motion.div>


  )
}



export default Article