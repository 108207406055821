import React from 'react';
import { motion } from 'framer-motion';
import { ClearCard, CustomOverlay, CustomOverlayTag, HistoryCard, Line, MyTab, MyTabs, ToolBGContainer } from '../components/container.styles';
import { Col, Container, Row } from 'react-bootstrap';
import { LogoText, SloganText, TabTitle } from '../components/text.styles';
import { supabase, useAuth } from '../components/client';
import { useNavigate } from 'react-router-dom';
import { HistoryBG } from '../assets';
import { useState, useEffect } from 'react';
import { Gallery } from "react-grid-gallery";
import ScaleLoader from 'react-spinners/ScaleLoader';
import ImageComponent from '../components/ImageComponent';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const formatDate = (date) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const formattedDate = new Date(date).toLocaleString("en-AU", options);
    return formattedDate;
};

export const CustomCaption = ({ data }) => {
    if (typeof data.description !== 'undefined') {
        return data.description;
    } else {
        return <div>Loading...</div>;
    }
};

const DataCard = ({ data, showMoreLimit = 100 }) => {
    const [showMore, setShowMore] = useState(false);
  
    const toggleShowMore = () => setShowMore(!showMore);
  
    const generatedText = showMore
      ? data.generated_text
      : data.generated_text.slice(0, showMoreLimit) + '...';
  
    return (
      <ClearCard
        style={{ backgroundColor: '#FFFFFF8F', color: 'black', margin: '0% 5% 1% 5%' }}
      >
        <Row>
          <Col xs={12} md={6}>
            <h6 className='m-2'>
              Generated at: <b>{formatDate(data.created_at)}</b> with <b>{data.tool}</b>
            </h6>
          </Col>
          <Col xs={12} md={6} style={{ overflow: 'auto', textAlign: 'left'}}>
            <div>{generatedText}</div>
            {data.generated_text.length > showMoreLimit && (
              <button style={{borderRadius: '8px', padding: '1% 2% 1% 2%' ,background: '#051920',color:'white', border: 'none',backdropFilter:'blur(10px)'}}onClick={toggleShowMore} className='mt-2'>
                {!showMore ? <MdExpandMore /> : <MdExpandLess />}
              </button>
            )}
          </Col>
        </Row>
      </ClearCard>
    );
  };
  

  const AudioCard = ({ data, showMoreLimit = 100 }) => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => setShowMore(!showMore);
  
    const content = showMore
      ? data.content
      : data.content.slice(0, showMoreLimit) + '...';
  
  
    return (
      <ClearCard style={{ backgroundColor: '#FFFFFF8F', color: 'black', margin: '0% 5% 1% 5%'}}>
        <Row>

        <Col xs={12} md={6} style={{overflow: 'auto', textAlign: 'center'}}>
        Generated at: <b>{formatDate(data.created_at)}</b> with <b>{data.voice}</b>
          <audio className="my-4 w-100" src={data.audio} controls />
        </Col>
        <Col xs={12} md={6} style={{overflow: 'auto', textAlign: 'left'}}>
        <div>{content}</div>
        {data.content.length > showMoreLimit && (
              <button style={{borderRadius: '8px', padding: '1% 2% 1% 2%' ,background: '#051920',color:'white', border: 'none',backdropFilter:'blur(10px)'}}onClick={toggleShowMore} className='mt-2'>
                {!showMore ? <MdExpandMore /> : <MdExpandLess />}
              </button>
            )}
        </Col>
        </Row>
      </ClearCard>
    );
  };
  
  
const History = () => {

    const currentUser = useAuth();
    let navigate = useNavigate();

    const [generatedTexts, setGeneratedTexts] = useState([]);
    const [generatedAudios, setGeneratedAudios] = useState([]);
    const [images, setImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [activeTabKey, setActiveTabKey] = useState('images');

    
    useEffect(() => {

        async function fetchGeneratedImages() {
            console.log('called');
            try {
                const { data, error } = await supabase
                    .from("images")
                    .select("*")
                    .eq("user_id", currentUser.user.id)
                    .order("date_generated", { ascending: false });
        
                if (error) {
                    console.error(error);
                } else if (data) {
                    const images = [];
        
                    for (const image of data) {
                        try {
                            const img = new Image();
                            img.src = image.image_url;
        
                            await new Promise((resolve, reject) => {
                                img.onload = resolve;
                                img.onerror = reject;
                            });
        
                            const width = img.naturalWidth;
                            const height = img.naturalHeight;
                            images.push({
                                src: image.image_url,
                                width: width,
                                height: height,
                                customOverlay: (
                                    <CustomOverlay>{image.prompt}</CustomOverlay>
                                ),
                                tags: [
                                    { value: formatDate(image.date_generated) },
                                    { value: image.aspect, title: image.aspect },
                                    { value: image.privacy, title: image.privacy },
                                    { value: image.guidance, title: image.guidance },
                                ],
                            });
                        } catch (error) {
                            console.error(error);
                        }
                    }
        
                    setImages(images);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchGeneratedImages();

    },[currentUser.user.id]);
    


    useEffect(() => {
        async function fetchGeneratedTexts() {
            const { data, error } = await supabase
                .from('generated_texts')
                .select('*')
                .eq('user_id', currentUser.user.id)
                .order('created_at', { ascending: false })

            if (error) {
                console.error(error);
            }

            if (data) {
                setGeneratedTexts(data);
            }

        }

        fetchGeneratedTexts();
    }, [currentUser.user.id]);

    useEffect(() => {
        async function fetchGeneratedAudio() {
            const { data, error } = await supabase
                .from('generated_audio_tortoisetts')
                .select('*')
                .eq('user_id', currentUser.user.id)
                .order('created_at', { ascending: false })

            if (error) {
                console.error(error);
            }

            if (data) {
                setGeneratedAudios(data);
            }

        }

        fetchGeneratedAudio();
    }, [currentUser.user.id]);

    function handleClick(index) {
        if (!isOpen && images.length > 0) {
            setIsOpen(true);
            setPhotoIndex(index);
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
            <ToolBGContainer
                color1={"rgba(89,89,89,0.3)"}
                color2={"rgba(47,46,44,0.8)"}
                img={HistoryBG}
            >
                <Container>
                
                <Row>
                    <Col lg={12}>
                        <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
                        <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
                        <Line className="mt-3" />
                    </Col>
                    <Col lg={12} className="my-2">
                        <HistoryCard>
                        <TabTitle>
                            HISTORY
                        </TabTitle>
                        <MyTabs
                            defaultActiveKey={activeTabKey}
                            onSelect={(key) => setActiveTabKey(key)}
                            id="tool-tabs"
                            className="mb-3"
                            variant="pills"
                            style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}
                        >
                                    <MyTab eventKey="images" title="Images">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {loading ? (
                                                <ScaleLoader color="#fff" size={10} speedMultiplier="1" />
                                            ) : (
                                                <Gallery
                                                    key={activeTabKey}
                                                    images={images}
                                                    enableImageSelection={false}
                                                    onClick={handleClick}
                                                    margin={4}
                                                    defaultContainerWidth={300}
                                                    tagStyle={{
                                                        background: 'black',
                                                        padding: '4px',
                                                        color: 'white',
                                                        borderRadius: '4px',
                                                        opacity: '0.8',
                                                        fontSize: '12px',
                                                    }}
                                                    thumbnailImageComponent={ImageComponent}
                                                />
                                            )}
                                        </div>

                                        {isOpen && images && images.length > 0 && (
                                         <Lightbox
                                         open={isOpen}
                                         close={handleClose}
                                         index={photoIndex}
                                         slides={images.map((image) => ({
                                           src: image.src,
                                           width: image.width,
                                           height: image.height,
                                           description: (
                                             <div style={{ fontFamily: 'Comfortaa' }}>
                                               <CustomOverlayTag>{image.customOverlay.props.children}</CustomOverlayTag>
                                               <br />
                                               {image.tags.map((tag) => (
                                                 <CustomOverlayTag key={tag.value} tag={tag}>
                                                   {tag.value}
                                                 </CustomOverlayTag>
                                               ))}
                                             </div>
                                           )
                                         }))}
                                         animation={{ zoom: 400 }}
                                         zoom={{
                                           maxZoomPixelRatio: 2,
                                           zoomInMultiplier: 2,
                                           doubleTapDelay: 300,
                                           doubleClickDelay: 500,
                                           doubleClickMaxStops: 2,
                                           keyboardMoveDistance: 50,
                                           wheelZoomDistanceFactor: 100,
                                           pinchZoomDistanceFactor: 100,
                                           scrollToZoom: true
                                         }}
                                         startIndex={photoIndex}
                                         plugins={[Captions, Thumbnails, Zoom,Fullscreen]}
                                       />                                       
                                        )}
                                    </MyTab>

                            <MyTab eventKey="text" title="Text">
                                    {generatedTexts.length > 0 ? (
                                        generatedTexts.map((text) => (
                                            <DataCard key={text.id} data={text} />
                                        ))
                                    ) : (
                                        <p>No generated texts to display.</p>
                                    )}
                            </MyTab>

                            <MyTab eventKey="audio" title="Audio">
                                    {generatedAudios.length > 0 ? (
                                        generatedAudios.map((audio) => (
                                            <AudioCard key={audio.id} data={audio} />
                                        ))
                                    ) : (
                                        <p>No generated audio to display.</p>
                                    )}

                            </MyTab>
                        </MyTabs>
                        </HistoryCard>
                    </Col>
                </Row>
                </Container>
            </ToolBGContainer>
        </motion.div>
    )
}

export default History