import React from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { NormalButton } from '../button.styles';
import { ClearDropdown, ScrollableDropdownMenu, StyledRange } from '../container.styles';
import { Label, Notes, SliderValue, Sublabel } from '../text.styles';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ScaleLoader from 'react-spinners/ScaleLoader';

// GenerateForm component
// This component is responsible for rendering a dynamic form based on the provided configuration.
export const GenerateForm = ({ config, onFormSubmit, version, model, loading }) => {
  // getDefaultValues function is used to set the default values of the form inputs
  const getDefaultValues = () => {
    const defaultValues = {};
    config.inputs.forEach((input) => {
      defaultValues[input.id] = input.defaultValue;
    });
    return defaultValues;
  };

  // useForm hook from react-hook-form library is used to manage form state and validation
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValues(),
    shouldUnregister: false, 
  });

  // onSubmit function is called when the form is submitted
  const onSubmit = (data, e) => {
    e.preventDefault();
    
    const formData = { ...data }; 
  
    // check if version and model exist and add them to formData if they do
    if (version) {
      formData.version = version;
    }
    
    if (model) {
      formData.model = model;
    }

    onFormSubmit(formData);
  };

  // areRequiredFieldsEmpty function checks if any required field is empty
  const areRequiredFieldsEmpty = () => {
    let empty = false;
    config.inputs.forEach((input) => {
      if (input.required && (watch(input.id) === undefined || watch(input.id) === '')) {
        empty = true;
      }
    });
    return empty;
  };

  // Rendering the form and its inputs
  return (
    <Form className='d-flex flex-column align-items-stretch justify-content-center' onSubmit={handleSubmit(onSubmit)}>
      {config.inputs.map((input) => {
        const { name, id, type, options, required, rows, defaultValue, validation } = input;

         // Render a dropdown input
        if (type === 'dropdown') {
          const dropdownValidation = validation?.validate;

          return (
            <ClearDropdown key={id}>
              <Sublabel>{name}</Sublabel>
              <Dropdown.Toggle variant="success" disabled={loading}>
                {watch(id) || defaultValue}
              </Dropdown.Toggle>

              <ScrollableDropdownMenu>
                {options.map((option) => (
                  <Dropdown.Item
                    key={option}
                    onClick={() => setValue(id, option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </ScrollableDropdownMenu>
              {dropdownValidation && (
                <Sublabel className="text-danger">{errors[id]?.message}</Sublabel>
              )}
            </ClearDropdown>
          );
        }

        // Render a range input
        if (type === 'range') {
          const { min, max, step } = input;
          return (
            <div key={id} className='d-grid justify-items-center align-items-center' style={{
              justifyContent: "space-around",
              display: "grid",
              justifyItems: "center",
              alignItems: "center",
              gridAutoFlow: "row"
            }}>
              <Sublabel>{name} </Sublabel>
              <div key={id} className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                <Label>{min}</Label>
                <StyledRange
                  {...register(id, {
                    required,
                    validate: validation && validation.validate
                  })}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  disabled={loading}
                  data-tooltip-content={watch(id)}
                  data-tooltip-id="slider-tooltip"
                />
                <Label>{max}</Label>
                <SliderValue>{watch(id) || defaultValue}</SliderValue>
                <Tooltip id="slider-tooltip" />
              </div>
            </div>

          );
        }

        // Render a textarea input
        if (type === 'textarea') {
          return (
            <div key={id} className='d-flex flex-column align-items-center justify-content-center'>
              <Sublabel>{name}</Sublabel>
              <Form.Group key={id} className="mb-3" style={{ width: '12rem' }}>
                <Notes
                  {...register(id, {
                    required
                  })}
                  as="textarea"
                  rows={rows}
                  disabled={loading}
                >
                  {errors[id] && <Sublabel className="bg-danger rounded">Please enter a {name.toLowerCase()}.</Sublabel>}
                </Notes>
              </Form.Group>
            </div>
          );
        }

        // If none of the above conditions are met, return null (which won't be rendered)
        return null;
      })}
      <NormalButton
        hidden={areRequiredFieldsEmpty()}
        type="submit"
        disabled={loading}
      >
        {loading ? (
          <ScaleLoader color="#fff" height={11} width={2} speedMultiplier="2.3" />
        ) : (
          "Generate"
        )}
      </NormalButton>
    </Form>
  );
};