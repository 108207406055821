import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import SegmentCard from '../components/storyteller/SegmentCard';
import { motion } from 'framer-motion';
import { ClearDropdown, Line, ScrollableDropdownMenu, ToolBGContainer } from '../components/container.styles';
import { StorytellerBG } from '../assets';
import { LogoText, SloganText, Sublabel } from '../components/text.styles';
import { useNavigate } from 'react-router-dom';
import useSegments from '../components/storyteller/UseSegments';
import { AudioInput, AudioInputLabel, AudioSelector, ButtonContainer, CustomChooseFileButton, CustomColorButton, HiddenAudioInput, StorybookButton, StorybookCard, StorybookModel, StorybookPreviewCard, StorybookSlider, StorybookSwitch, SwitchTextContainer } from '../components/storyteller/storybook.styles';
import { useVoices } from '../components/listen/Voices';
import { BsGearFill, BsMusicNote } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TwitterPicker } from 'react-color';
import { MyDynamicVideo } from '../remotion/MyDynamicVideo';
import { Player } from '@remotion/player';


const Storybook = () => {
    const voices = useVoices();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [audioFile, setAudioFile] = useState(null);
    const availableFonts = ["Arial", "Verdana", "Tahoma", "Roboto", "Times New Roman", "Georgia", "Helvetica", "Courier", "Comic Sans MS", "Comfortaa"];
    const subtitleDisplay = ["Sentence", "Sequence", "Word", "Phrase", "Full"];
    const [activeColor, setActiveColor] = useState('subtitleColor');
    const [loading, setLoading] = useState(false);

    const calculateTotalDurationInFrames = (segments) => {
        const fps = 30;
        const totalDurationInSeconds = segments.reduce((total, segment) => total + segment.duration, 0);
        return totalDurationInSeconds * fps;
    };


    const [projectSettings, setProjectSettings] = useState({
        width: 1280,
        height: 720,
        backgroundMusic: '',
        font: availableFonts[0],
        fontSize: 30,
        subtitlePosition: "bottom",
        subtitleDisplay: "Sentence",
        subtitleColor: "white",
        subtitleBackground: "black",
        enableImageZoom: "false",
        enableFadeBetweenSegments: "true",
        enableVisualizer:"false",
        bgmVolume: 50
    });

    useEffect(() => {
        console.log(projectSettings)
    }, [projectSettings])

    // Call the useSegments hook with an initial array of one empty segment
    const { segments, addSegmentAbove, addSegmentBelow, removeSegment, updateSegment, generateAudio } = useSegments([{ text: '', audio: null, media: "", duration: 3, voice: (voices.length > 0 ? voices[0] : '') }], voices, projectSettings);

    useEffect(() => {
        if (voices.length > 0) {
            const updatedSegment = {
                ...segments[0],
                voice: voices[0],
            };
            updateSegment(0, updatedSegment);
        }
    }, [voices]);

    const getAspectRatio = (width, height) => {
        const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
        const divisor = gcd(width, height);
        return `${width / divisor}:${height / divisor}`;
    };

    function capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }
    // Get the navigate function from the useNavigate hook provided by react-router-dom
    let navigate = useNavigate();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.2 }}>
            <ToolBGContainer color1={'rgba(8, 6, 36,0.7)'} color2={'rgba(221, 114, 83,0.4)'} img={StorytellerBG} className="p-0">
                <Container>
                    <Row>
                        <Col>
                            <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
                            <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
                        </Col>
                        <Line className="mt-3" />
                    </Row>
                    {console.log(projectSettings)}
                    <Row className="align-items-center justify-content-between">
                        <Col md={6} className="d-flex justify-content-center">

                            <div style={{ overflow: 'auto', maxHeight: '700px' }}>
                                {segments.map((segment, index) => (
                                    <SegmentCard
                                        key={index}
                                        index={index}
                                        segment={segment}
                                        updateSegment={(index, newSegment, media) => updateSegment(index, newSegment, media)}
                                        addSegmentAbove={addSegmentAbove}
                                        addSegmentBelow={addSegmentBelow}
                                        removeSegment={() => removeSegment(index)}
                                        canRemove={segments.length > 1}
                                        generateAudio={generateAudio}
                                        voiceList={voices}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                ))}
                            </div>
                        </Col>

                        <Col md={6} className="d-flex justify-content-center">
                            <StorybookPreviewCard>
                                <Sublabel>How to Use:</Sublabel>
                                <Sublabel>
                                    Create your video by adding text and media to each segment. To generate text-to-speech audio, simply click the <BsMusicNote /> icon. Enjoy crafting your story!
                                    <br />

                                </Sublabel>

                                <Player
                                    component={MyDynamicVideo}
                                    compositionWidth={projectSettings.width}
                                    compositionHeight={projectSettings.height}
                                    durationInFrames={Math.max(30, Math.ceil(calculateTotalDurationInFrames(segments)))}
                                    inputProps={{ projectSettings, segments }}
                                    fps={30}
                                    controls
                                    style={{ width: "500px", height: "500px" }}
                                />

                                <StorybookButton onClick={handleShow}><BsGearFill /></StorybookButton>

                            </StorybookPreviewCard>

                        </Col>
                        {/* <Button onClick={addSegment}>Add Segment</Button> */}
                    </Row>
                </Container>
                <StorybookModel centered show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Project Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="justify-content-center mt-3">
                            <AudioSelector>
                                <Row className="justify-content-center mt-3">
                                    <Col md={12} className="d-flex justify-content-center">
                                        <SwitchTextContainer>
                                            <StorybookSwitch>
                                                <input
                                                    type="checkbox"
                                                    checked={projectSettings.enableImageZoom === "true"}
                                                    onChange={(e) =>
                                                        setProjectSettings({
                                                            ...projectSettings,
                                                            enableImageZoom: e.target.checked ? "true" : "false",
                                                        })
                                                    }
                                                />
                                                <span className="slider"></span>
                                            </StorybookSwitch>
                                            <h6 className="mt-1 text-center">Enable Image Zoom</h6>
                                        </SwitchTextContainer>
                                    </Col>
                                    <Col md={12} className="d-flex justify-content-center">
                                        <SwitchTextContainer>
                                            <StorybookSwitch>
                                                <input
                                                    type="checkbox"
                                                    checked={projectSettings.enableFadeBetweenSegments === "true"}
                                                    onChange={(e) =>
                                                        setProjectSettings({
                                                            ...projectSettings,
                                                            enableFadeBetweenSegments: e.target.checked ? "true" : "false",
                                                        })
                                                    }
                                                />
                                                <span className="slider"></span>
                                            </StorybookSwitch>
                                            <p className="mt-1 text-center">Enable Fade Between Segments</p>
                                        </SwitchTextContainer>
                                    </Col>
                                </Row>
                            </AudioSelector>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={6} className="d-flex justify-content-center">
                                <AudioSelector>

                                    <ClearDropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {projectSettings ? "Aspect: " + getAspectRatio(projectSettings.width, projectSettings.height) : 'Select Aspect'}
                                        </Dropdown.Toggle>

                                        <ScrollableDropdownMenu>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, width: 1440, height: 1080 })}>4:3</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, width: 1920, height: 1080 })}>16:9</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, width: 1920, height: 960 })}>2:1</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, width: 1080, height: 1080 })}>1:1</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, width: 1080, height: 2160 })}>1:2</Dropdown.Item>
                                        </ScrollableDropdownMenu>
                                    </ClearDropdown>
                                </AudioSelector>

                            </Col>
                            <Col md={6} className="d-flex justify-content-center">
                                <AudioSelector>

                                    <ClearDropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-font">
                                            {projectSettings ? "Font: " + projectSettings.font : 'Select Font'}
                                        </Dropdown.Toggle>

                                        <ScrollableDropdownMenu>
                                            {availableFonts.map((font) => (
                                                <Dropdown.Item key={font} onClick={() => setProjectSettings({ ...projectSettings, font })}>
                                                    {font}
                                                </Dropdown.Item>
                                            ))}
                                        </ScrollableDropdownMenu>
                                    </ClearDropdown>

                                    <StorybookSlider
                                        type="range"
                                        min="10"
                                        max="72"
                                        value={projectSettings.fontSize}
                                        onChange={(e) => setProjectSettings({ ...projectSettings, fontSize: e.target.value })}
                                    />
                                    <h6 className="pt-3">Font Size: {projectSettings.fontSize}</h6>
                                </AudioSelector>
                            </Col>
                            <Col md={12} className="d-flex justify-content-center">
                                <AudioSelector>
                                    <AudioInputLabel htmlFor="background-music-input">
                                        Background Music:
                                    </AudioInputLabel>
                                    <CustomChooseFileButton
                                        onClick={() => document.getElementById("background-music-input").click()}
                                    >
                                        Choose File
                                    </CustomChooseFileButton>
                                    <HiddenAudioInput
                                        type="file"
                                        id="background-music-input"
                                        accept="audio/*"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                    setProjectSettings({
                                                        ...projectSettings,
                                                        backgroundMusic: e.target.result,
                                                    });
                                                    console.log(projectSettings);
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                    <audio
                                        id="audio-preview"
                                        src={projectSettings.backgroundMusic}
                                        controls
                                        style={{
                                            marginTop: "8px",
                                            width: "100%",
                                            height: "32px",
                                            background: "#f0f0f0",
                                            borderRadius: "4px",
                                            outline: "none",
                                        }}
                                        hidden={!projectSettings.backgroundMusic}
                                    >
                                        Your browser does not support the audio element.
                                    </audio>
                                    <Row className="justify-content-center m-3">
                                        <Col md={12}>
                                            <StorybookSlider
                                                type="range"
                                                min="0"
                                                max="100"
                                                value={projectSettings.bgmVolume}
                                                onChange={(e) => {
                                                    const newValue = parseInt(e.target.value, 10);
                                                    setProjectSettings({
                                                        ...projectSettings,
                                                        bgmVolume: newValue,
                                                    });
                                                }}
                                            />
                                        </Col>
                                        <Col md={12} className="d-flex justify-content-center">
                                            <h6 className="pt-3">BGM Volume: {projectSettings.bgmVolume}</h6>
                                        </Col>
                                    </Row>
                                </AudioSelector>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <AudioSelector>
                                <Col md={6} className="d-flex flex-column align-items-center">
                                    <ButtonContainer>
                                        <CustomChooseFileButton onClick={() => setActiveColor('subtitleColor')}>
                                            Subtitle Color
                                        </CustomChooseFileButton>
                                        <CustomChooseFileButton onClick={() => setActiveColor('subtitleBackground')}>
                                            Subtitle Background
                                        </CustomChooseFileButton>
                                    </ButtonContainer>

                                    <p>{activeColor === 'subtitleColor' ? 'Subtitle Color' : 'Subtitle Background'}:</p>
                                    <TwitterPicker
                                        color={
                                            projectSettings[activeColor] ||
                                            (activeColor === 'subtitleColor' ? '#ffffff' : '#000000')
                                        }
                                        onChangeComplete={(color) =>
                                            setProjectSettings({
                                                ...projectSettings,
                                                [activeColor]: color.hex,
                                            })
                                        }
                                    />
                                </Col>
                            </AudioSelector>
                        </Row>

                        <Row>
                            <Col md={12} className="d-flex justify-content-center">
                                <AudioSelector>
                                    <ClearDropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-font">
                                            {projectSettings ? "Subtitle Position: " + capitalize(projectSettings.subtitlePosition) : 'Select Subtitle Position'}
                                        </Dropdown.Toggle>

                                        <ScrollableDropdownMenu>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, subtitlePosition: "top" })}>Top</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, subtitlePosition: "center" })}>Center</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setProjectSettings({ ...projectSettings, subtitlePosition: "bottom" })}>Bottom</Dropdown.Item>
                                        </ScrollableDropdownMenu>
                                    </ClearDropdown>

                                    <ClearDropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-font">
                                            {projectSettings ? "Subtitle Display: " + projectSettings.subtitleDisplay : 'Select Subtitle Display'}
                                        </Dropdown.Toggle>

                                        <ScrollableDropdownMenu>
                                            {subtitleDisplay.map((display) => (
                                                <Dropdown.Item key={display} onClick={() => setProjectSettings({ ...projectSettings, subtitleDisplay: display })}>
                                                    {display}
                                                </Dropdown.Item>
                                            ))}
                                        </ScrollableDropdownMenu>
                                    </ClearDropdown>
                                </AudioSelector>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <StorybookButton variant="secondary" onClick={handleClose}>
                            Close
                        </StorybookButton>
                        <StorybookButton variant="primary" onClick={handleClose}>
                            Save Changes
                        </StorybookButton>
                    </Modal.Footer>
                </StorybookModel>
            </ToolBGContainer>
        </motion.div>
    );
};

export default Storybook;
