import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, InputGroup, ListGroup, Row, Table } from 'react-bootstrap';
import { ClearCard, Line, ToolBGContainer } from '../components/container.styles';
import { LogoText, SloganText, Sublabel } from '../components/text.styles';
import { StorytellerBG } from '../assets';
import { motion } from 'framer-motion';
import { supabase, useAuth } from '../components/client';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { BiTrash } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';
import { ProjectListButton, ProjectListCard, ProjectListDeleteButton, ProjectListTable, StorybookButton, StorybookCard, StorybookInput } from '../components/storyteller/storybook.styles';

const ProjectList = () => {

    let navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const [newProjectName, setNewProjectName] = useState('');
    const [loading, setLoading] = useState(false);

    const userid = useAuth().user.id;
    useEffect(() => {
        fetchProjects();
    }, []);

    const deleteProject = async (projectId) => {
        try {

            const { data, error } = await supabase
                .from('video_projects')
                .delete()
                .eq('id', projectId)


            if (error) {
                console.error('Error deleting project:', error.message);
                return;
            }

            // Update the projects list after deleting a project
            setProjects(projects.filter(project => project.id !== projectId));
        } catch (error) {
            console.error('Error deleting project:', error.message);
        }
    };


    const fetchProjects = async () => {
        try {
            const { data, error } = await supabase
                .from('video_projects')
                .select('*')
                .eq("user_id", userid);

            if (error) {
                console.error('Error fetching projects:', error.message);
                return;
            }

            setProjects(data);
        } catch (error) {
            console.error('Error fetching projects:', error.message);
        }
    };

    const createProject = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await supabase
                .from('video_projects')
                .insert([
                    { project_name: newProjectName, user_id: userid, width: '1080', height: '1080' },
                ]);

            console.log(response);

            if (response.status === 201) {
                const { data, error } = await supabase
                    .from('video_projects')
                    .select('*')
                    .eq('project_name', newProjectName);

                if (data && data.length > 0) {
                    // Create a default segment for the new project
                    const projectId = data[0].id;
                    const { error: segmentError } = await supabase
                        .from('segments')
                        .insert([
                            {
                                video_project_id: projectId,
                                segment_order: 1, // Use an integer value for segment_order, starting from 0
                                text: '',
                                image_url: null,
                                audio_url: null,
                            },
                        ]);

                    if (segmentError) {
                        console.error('Error creating default segment:', segmentError.message);
                    } else {
                        setProjects((prevProjects) => [...prevProjects, data[0]]);
                    }
                }

            } else {
                console.error('Error creating the record:', response.error);
            }



            setNewProjectName('');
            fetchProjects();
        } catch (error) {
            console.error('Error creating project:', error.message);
        } finally {
            setLoading(false);
        }
    };



    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
            <ToolBGContainer
                color1={"rgba(6, 35, 45, 0.9)"}
                color2={"rgba(56, 183, 191, 0.7)"}
                img={StorytellerBG}
                className='p-0'
            >
                <Container>
                    <Row>
                        <Col>
                            <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
                            <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
                        </Col>
                        <Line className="mt-3" />
                    </Row>
                    <Row>
                        <Col>
                            <ProjectListCard>
                                <h3 className='text-light'>Video Projects</h3>
                                <Form onSubmit={createProject}>

                                    <Row>
                                        {/* <Sublabel className="fw-bold mb-2" >Create a new project?</Sublabel> */}

                                        <Col md={9}>
                                            <StorybookInput
                                                type="text"
                                                value={newProjectName}
                                                placeholder='Create a new project...'
                                                onChange={(e) => setNewProjectName(e.target.value)}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <ProjectListButton variant="secondary" type="submit" disabled={loading || !newProjectName}>
                                                Create
                                            </ProjectListButton>
                                        </Col>
                                    </Row>


                                </Form>

                                <Sublabel className="fw-bold m-3">
                                    Choose an existing project?
                                </Sublabel>
                                <ProjectListTable className="rounded" responsive bordered hover hidden={loading}>
                                    <thead>
                                        <tr>
                                            <th>Project Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projects.length == 0 && (
                                            <tr>
                                                <td colSpan={2}>
                                                    <Sublabel>No Projects Yet!</Sublabel>
                                                </td>
                                            </tr>
                                        )}
                                        {projects.map((project) => (
                                            <tr key={project.id}>
                                                <td>{project.project_name}</td>
                                                <td>
                                                    <div className="d-flex justify-content-between">
                                                        <ProjectListButton className="me-2" onClick={() => navigate(`/storyteller/${project.id}`)}>View</ProjectListButton>
                                                        <ProjectListDeleteButton className="" onClick={() => deleteProject(project.id)}>Delete</ProjectListDeleteButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </ProjectListTable>
                                <div className="d-flex justify-content-center">
                                    <ScaleLoader color="#5a9" loading={loading} />
                                </div>
                            </ProjectListCard>
                        </Col>
                    </Row>
                </Container>
            </ToolBGContainer>
        </motion.div>
    );
};

export default ProjectList;
