import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Alert, Col, Container, Form, Row,  Modal } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { AuthButton, NormalButton } from '../components/button.styles';
import { supabase } from '../components/client';
import { AuthCard, ClearCard, Line, PopUp, ToolBGContainer } from '../components/container.styles';
import { useAuth } from '../components/client'
import { Logout } from '../components/logout';
import { LogoText, MyLink, RedirectLabel, SloganText } from '../components/text.styles';
import BG from '../assets/underwater-forest.png'
import { useForm } from 'react-hook-form';
import { LoginBG } from '../assets';
import PasswordReset from '../components/ForgetPassword';

const Register = () => {

  let navigate = useNavigate();


  //Checks if user has entered a proper email with an @
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //State for disabling sign-up button once pressed
  const [loading, setLoading] = useState(false);
  const {currentUser,signUp} = useAuth();
  
  // state variables to store the email and password input by the user, and any validation errors
  const [error, setError] = useState('');

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = async formData =>{
    console.log(formData);
    setLoading(true);

    try {
      const { data, error } = await signUp(
        {
          email: formData.email,
          password: formData.password,
          options: {
            data: {
              first_name: formData.firstName,
              last_name: formData.lastName
            }
          }
        })
        console.log(data);
      if (error) throw error
      
      const date = new Date();

      
    } catch (error) {
      console.log(error);
      setError(error.message);
      handleShow();
    } finally {
      setLoading(false)
    }
  };


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    
    <ToolBGContainer
      color1={"rgba(40,10,12,0.4)"}
      color2={"rgba(25,11,18,0.9)"}
      img={LoginBG}
    >
      <Container>
      <Row>
        <Col>
          <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
          <SloganText className="pb-3" onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column align-items-center">
        <ClearCard>
                <Col md={8} lg={8}>
                  <h3>SIGN UP</h3>
                  
                  <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                  <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name:</Form.Label>
                     <Form.Control type="text"  {...register("firstName",{ required: true })} />
                     {errors.firstName && <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label>Last Name:</Form.Label>
                     <Form.Control type="text"  {...register("lastName",{ required: true })} />
                     {errors.lastName &&  <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>
                    </Col>

                    <Col md={12}>
                    <Form.Group className="mb-3">
                    <Form.Label>E-mail:</Form.Label>
                     <Form.Control type="email"  {...register("email",{ required: true })}/>
                     {errors.email && <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>


                    <Form.Group className="mb-3">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" {...register("password", { required: true })} /> 
                    {errors.password &&  <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>
                    </Col>
                    </Row>
                    
                    <NormalButton hidden={loading} type="submit" onClick={handleSubmit}>SIGN UP</NormalButton>
                    {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}


                    <PopUp show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Sign Up Error</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{error}</Modal.Body>
                      <Modal.Footer>
                        <NormalButton variant="secondary" onClick={handleClose}>
                          Close
                        </NormalButton>
                      </Modal.Footer>
                    </PopUp>
                  </form>
                  <Line className='mt-4' />
                  <Row className='extra-btns d-flex flex-direction-row align-items-baseline'>
                    <Col lg={6}> 
                    <AuthButton className="mt-4" type="link" onClick={() => navigate("/login")}>
                         Have an account?
                        </AuthButton>
                    </Col>
                    <Col lg={6}>
                     <PasswordReset />
                    </Col>
                  </Row>

                </Col>
              </ClearCard>
        </Col>
        <PopUp show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}
        </Modal.Body>
        <Modal.Footer>
          <NormalButton variant="secondary" onClick={handleClose}>
            Close
          </NormalButton>
        </Modal.Footer>
      </PopUp>
      </Row>
      </Container>
    </ToolBGContainer>
  </motion.div>
  )
}

export default Register