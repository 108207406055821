import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ClearCard, Line, MyTab, MyTabs, ToolBGContainer } from '../components/container.styles';
import { ChildrenStoryGen } from '../components/fiction-tools/ChildrenStoryGen';
import { FantasyWorldGen } from '../components/fiction-tools/FantasyWorldGen';
import { FictionalStoryGen } from '../components/fiction-tools/FictionalStoryGen';
import { MoralStoryGen } from '../components/fiction-tools/MoralStoryGen';
import { LogoText, Notes, SloganText, TabTitle } from '../components/text.styles';
import { FictionBG } from '../assets';

const Story = () => {
  let navigate = useNavigate(); 

  const [dataFromChild, setDataFromChild] = useState("");

  function handleDataFromChild(data) {
    console.log(data);
    // do something with the data passed from the child component
    setDataFromChild(data);
  }


  return (
    <motion.div initial={{opacity:0}} animate={{ opacity:1 }} exit={{opacity:0}} transition={{ delay: .2 }}>

    <ToolBGContainer 
    color1={"rgba(37,45,49,0.3)"}
    color2={"rgba(28,38,43,0.8)"}
    img={FictionBG}
    >
    <Container>
     
     <Row className="mb-5">
      <Col  xs={12} sm={12} md={12} lg={12}>
          <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
          <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
        </Col>
        <Line className="mt-3"/>
      </Row>
      <Row>
          <Col lg={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <TabTitle>
              STORY TOOLS
            </TabTitle>
            <MyTabs
              defaultActiveKey="fictional"
              id="tool-tabs"
              className="mb-3"
              variant="pills"
              style={{"display":"flex","justifyContent":"center","alignItems":"center"}}
            >
              <MyTab eventKey="fictional" title="Fictional">
                <FictionalStoryGen onDataFromChild={handleDataFromChild} />
              </MyTab>
              <MyTab eventKey="children" title="Children">
                <ChildrenStoryGen onDataFromChild={handleDataFromChild} />
              </MyTab>
              {/* <MyTab eventKey="biography" title="Short Biographies">
                <ShortBiographyGen onDataFromChild={handleDataFromChild} />
              </MyTab> */}
              <MyTab eventKey="moral" title="Moral">
                <MoralStoryGen onDataFromChild={handleDataFromChild} />
              </MyTab>
              <MyTab eventKey="fantasy" title="Fantasy World">
                <FantasyWorldGen onDataFromChild={handleDataFromChild} />
              </MyTab>
            </MyTabs>
          </Col>
          <Col lg={6} style={{ display: 'grid', alignContent: 'center' }}>
            <Col lg={12}>
              <ClearCard>
                <h3>Output:</h3>
                <Notes
                  readOnly
                  rows={4}
                  value={dataFromChild}>
                </Notes>
              </ClearCard>
            </Col>
          </Col>
        </Row>
        </Container>
    </ToolBGContainer>
</motion.div>


  )
}

export default Story