import { Col } from "react-bootstrap";
import { ClearCont, Image } from "./container.styles";
import { useNavigate } from 'react-router-dom';
import { Sublabel } from "./text.styles";

const DashboardCard = ({ page, image, title, subtitle }) => {
  let navigate = useNavigate();
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.2,
      },
    },
  };
  return (
    <Col xs={12} sm={12} md={6} lg={3} >
        <ClearCont onClick={() => navigate(page)}  
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        className="pb-2 mx-0">
          <Image src={image} />
          <h3 className="px-2">{title}</h3>
          <Sublabel className="px-2">{subtitle}</Sublabel>
        </ClearCont>
    </Col>
  );
};

export default DashboardCard;
