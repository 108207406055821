import React, { useEffect, useMemo, useState } from "react";
import { Sublabel } from "../components/text.styles";
import {
  useCurrentFrame,
  interpolate,
  Audio,
  useVideoConfig,
  Sequence,
} from "remotion";

const videoExtensions = ["mp4", "webm", "ogg"];

const randomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

const isVideo = (url) => {
  const fileExtension = url.split(".").pop();
  return videoExtensions.includes(fileExtension);
};

export const calculateTotalDuration = (segmentDurationsInFrames) => {
  return segmentDurationsInFrames.reduce((a, b) => a + b, 0);
};

const calculateSegmentDurations = async (segments, fps) => {
  const durations = await Promise.all(
    segments.map((segment) => {
      return new Promise((resolve) => {
        if (segment.audio) {
          const audioElement = document.createElement("audio");
          audioElement.src = segment.audio;
          audioElement.addEventListener("loadedmetadata", () => {
            const durationInSeconds = audioElement.duration;
            console.log(
              "Duraction Check --->",
              durationInSeconds,
              Math.floor(durationInSeconds * fps)
            );
            resolve(Math.floor(durationInSeconds * fps));
          });
        } else {
          resolve(90); // Default duration if there's no audio or text
        }
      });
    })
  );
  return durations;
};


const getCurrentSegment = (segments, frame, segmentDurationsInFrames) => {
  const segmentIndex = segmentDurationsInFrames.findIndex(
    (duration, idx) =>
      frame >=
        segmentDurationsInFrames.slice(0, idx).reduce((a, b) => a + b, 0) &&
      frame <
        segmentDurationsInFrames.slice(0, idx + 1).reduce((a, b) => a + b, 0)
  );
  const currentSegmentIndex = Math.min(segmentIndex, segments.length - 1);
  return segments[currentSegmentIndex];
};

const getTextOpacity = (frameInSegment, currentSegmentDuration) => {
  const fadeInEnd = 30;
  const fadeOutStart = currentSegmentDuration - 30;

  if (frameInSegment <= fadeInEnd) {
    return interpolate(frameInSegment, [0, fadeInEnd], [0, 1]);
  } else if (frameInSegment >= fadeOutStart) {
    return interpolate(
      frameInSegment,
      [fadeOutStart, currentSegmentDuration],
      [1, 0]
    );
  } else {
    return 1;
  }
};

const randomGradient = `linear-gradient(45deg, ${randomColor()}, ${randomColor()})`;

const renderMedia = (currentSegment, randomGradient) => {
  if (!currentSegment) {
    return null;
  }

  if (!currentSegment.image) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: randomGradient,
        }}
      />
    );
  }

  if (isVideo(currentSegment.image)) {
    return (
      <video
        src={currentSegment.image}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        autoPlay
        loop
        muted
        playsInline
      />
    );
  } else {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${currentSegment.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    );
  }
};

export const MyComp = ({ segments, totalAudioLength ,durationInFrames  }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const [segmentDurationsInFrames, setSegmentDurationsInFrames] = useState<
    number[]
  >([]);

  useEffect(() => {
    const loadDurations = async () => {
      const durations = await calculateSegmentDurations(segments, fps);
      setSegmentDurationsInFrames(durations);
    };
    loadDurations();
  }, [segments, fps]);

  const currentSegment = useMemo(() => {
    return getCurrentSegment(segments, frame, segmentDurationsInFrames);
  }, [segments, frame, segmentDurationsInFrames]);

  const currentSegmentStart = segmentDurationsInFrames
    .slice(0, segments.indexOf(currentSegment))
    .reduce((a, b) => a + b, 0);

  const currentSegmentDuration =
    segmentDurationsInFrames[segments.indexOf(currentSegment)];
  const frameInSegment = frame - currentSegmentStart;
  const textOpacity = getTextOpacity(frameInSegment, currentSegmentDuration);
  

  return (
    <>
      {renderMedia(currentSegment, randomGradient)}
      {currentSegment && (
        <Sublabel
          style={{
            fontSize: 40,
            fontWeight: "bold",
            color: "white",
            textShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
            opacity: textOpacity,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontFamily: "Comfortaa",
          }}
        >
          {currentSegment.text}
        </Sublabel>
      )}
      {segments.map((segment, index) => {
        const segmentStart = segmentDurationsInFrames
          .slice(0, index)
          .reduce((a, b) => a + b, 0);
        const segmentEnd = segmentDurationsInFrames
          .slice(0, index + 1)
          .reduce((a, b) => a + b, 0);
        const segmentDuration = segmentEnd - segmentStart;

        // Change this line to use the provided audio duration or the default value of 90 frames (3 seconds)
        const audioDuration = segment.audio ? segmentDuration : 90;

        return (
          <div key={index} style={{ position: "absolute" }}>
            {segment.audio && segmentDuration > 0 && (
              <Sequence from={segmentStart} durationInFrames={audioDuration}>
                <Audio src={segment.audio} />
              </Sequence>
            )}
          </div>
        );
      })}
    </>
  );
};
