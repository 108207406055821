import React from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { useState } from 'react';
import { useAuth } from './client';
import { FiUser, FiLogOut } from 'react-icons/fi';
import { Image, SidebarContainer } from './container.styles';
import { Logo } from '../assets';
import { FloatingButton, Icon } from './button.styles';

const NavSidebar = () => {
  const auth = useAuth();
  const { toggleSidebar, collapseSidebar } = useProSidebar();
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    toggleSidebar();
    collapseSidebar();
  };



  const handleLogout = () => {
    auth.signOut();
  };



  return (
    <>
      <SidebarContainer>
          <Sidebar
            backgroundColor="#39abb290"
            style={{
              fontFamily: 'Comfortaa',
              position: 'absolute',
              height: '100%',
              zIndex: '100',
              backdropFilter: 'blur(20px)',
              boxShadow: '10px',
              border: 'none',
            }}
            transitionDuration={300}
            breakPoint='always'
            defaultCollapsed={true}
          >
            <Menu
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  if (level === 0)
                    return {
                      color: disabled ? '#ffffff' : '#ffffff',
                      backgroundColor: active ? '#00ffff' : undefined,
                      '&:hover': {
                        backgroundColor: '#007d7d',
                      },
                      fontWeight: disabled ? 'bold' : 'normal',
                    };
                },
              }}
            >
              <Image src={Logo} style={{ borderRadius: '0px' }} />

              <MenuItem className='text-center' disabled={true}>  Hi {auth.user.user_metadata.first_name}! </MenuItem>
              <MenuItem style={{bottom: '0'}} icon={<FiUser />}> Profile </MenuItem>
              <MenuItem icon={<FiLogOut />} onClick={handleLogout}> Log Out </MenuItem>
            </Menu>
          </Sidebar>
      </SidebarContainer>
      <FloatingButton onClick={handleToggle}>
      <Icon istoggled={isToggled ? 'true' : undefined} />
      </FloatingButton>
    </>

  );
};


export default NavSidebar;
