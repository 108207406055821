import { motion } from 'framer-motion';
import { Col, Dropdown, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import styled, { keyframes,createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #dadadb;
  animation: animate-underwater 2s linear infinite;
}

@keyframes animate-underwater {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100px 0;
  }
}
`;

export const Pulse = keyframes`
 0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px 6px #abaeb1;
  }
  100% {
    transform: scale(1);
  }
`



export const ColorShift = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(20deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
`


export const ClearCont = styled(motion.div)`
    border-radius: 12px;
    border: 0px solid rgba(255, 255, 255, 0.125);
    color:white; 
    margin: 20px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: all ease-in-out;
    box-shadow: 0px 0px 6px 0.2px #4c4c4c;
    background-color: rgb(123, 255, 255, 0.3);
    backdrop-filter: blur(4px) saturate(110%);
    -webkit-backdrop-filter: blur(4px) saturate(110%);

    :hover{
        transition: 0.3s;
        transform: translateY(-0.25em);
        border: 0px solid rgba(255, 255, 255, 0.125);
        animation-name: ${Pulse};
        animation-duration: 2s;
        animation-iteration-count: infinite;

    }

`;

export const BGContainer = styled.div`
    font-family: 'Comfortaa', cursive;
    height: 100vh;
    background: radial-gradient(at ${props => props.X}% ${props => props.Y}%, #69a6ad, #0b132a);
    padding: 1rem;
`

export const Image = styled.img`
    object-fit: cover;
    overflow: hidden;
    opacity: 1;
    border-radius: 12px;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
   // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    //filter: grayscale(100%);
    width: 100%;
`


export const HistoryCard = styled.div`
    backdrop-filter: blur(15px) saturate(110%);
    border-radius: 28px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    padding: 10px;
    color:white; 
    margin:5px;
    align-items: center;
`;

export const ClearCard = styled.div`
    backdrop-filter: blur(15px) saturate(110%);
    border-radius: 28px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    color:white; 
    padding: 2rem;
    margin:1rem;
    text-align: center;
    width: 90%;
    position: relative;
    display: grid;
  grid-auto-flow: row;
  justify-items: center;
    display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 5%;
      align-items: center !important;


    :hover{
    }

    .input{
        background:rgb(255, 255, 255,0.6);
    }
`;

export const Line = styled.hr`
  color: #f7fafb;
  margin: 0%;
`;

export const ToolBGContainer = styled.div`
    font-family: 'Comfortaa', cursive;
    background:  linear-gradient(0deg, ${props => props.color1} 0%,  ${props => props.color2} 100%), ${(props) => `url(${props.img})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    display: grid;
    grid-auto-flow: row;
    overflow-y: auto;
    //animation: ${ColorShift} 10s ease-in-out infinite;

    .container {
      padding: 0% 7% 7% 7%;
    }

`

export const MyTabs = styled(Tabs)`




  button.nav-link {
  color: #FFFFFF;
  border-radius: 28px;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: 5px;
  background:#252627;

  :hover{
      background-color: rgba(255,255,255,0.4);
    }
}

button.nav-link.active {
  backdrop-filter: blur(10px) saturate(150%);
  border-radius: 12px;
  color: #000;
  border-radius: 28px;
  background: #d6d6d6 !important;
  opacity: 1 !important;
  font-weight: bold;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: 5px;

  
  :hover{
      background-color: #4c4c4c;
    }
}


`;

export const MyTab = styled(Tab)`

`


export const AuthCard = styled.div`
    backdrop-filter: blur(5px) saturate(110%);
    border-radius: 28px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color:white; 
    padding: 2rem;
    margin:1rem;
    text-align: center;
    width: 75%;
    height: fit-content;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;

@media only screen and (max-width: 480px) {
    display: grid;
    grid-auto-flow: row;
}
`;


export const PopUp = styled(Modal)`
  .modal-content { 
    backdrop-filter: blur(25px) saturate(110%);
    border-radius: 28px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    background-color: rgb(230, 242, 255, 0.1);
    color:white; 
    font-family:Comfortaa,sans-serif;
    }
`;
export const CustomOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 100%;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 2px;
  font-size: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  height: 50%; /* added */
  width: 100%; /* added */
  display: none;
`;



export const CustomOverlayTag = styled.div`
  word-wrap: break-word;
  display: inline-block;
  background-color: transparent;
  height: auto;
  font-weight: 800;
  line-height: 1;
  padding: .2em .6em .3em;
  border-radius: .25em;
  color: white;
  vertical-align: baseline;
  margin: 2px;
  top: 0;
`


export const ChallengeImageContainer = styled.div`
  border-radius: 18px;
  overflow: hidden;
`;

export const ChallengeImage = styled.img`
  object-fit: contain !important; 
  margin-top: 30px;
  padding: 10px;
  width: 80% !important;
  aspect-ratio: 1;
`;

export const ChallengeCol = styled(Col)`
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: column;
  justify-items: center;
  align-content: center;
`


export const ClearDropdown = styled(Dropdown)`
  button.dropdown-toggle.btn.btn-success {
  
  border: 0px solid #000000 !important;
  box-shadow: 0px 0px 0px 0px #000000 !important;
  background: #FFFFFF38 !important;
  border-radius: 50px !important;
  margin: 15px !important;
}

`


export const ScrollableDropdownMenu = styled(Dropdown.Menu)`
  z-index: 999; // Add this line
  max-height: 155px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #0f3f45  !important;
  backdrop-filter: blur(30px);
  border-radius: 8px !important;
  color: white;

  a.dropdown-item{
    color: white;
  }

  a.dropdown-item:hover{
    background-color: #18466580 !important;
  }
  
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
`


export const StyledOption = styled.option`
  max-height: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
`;

export const NotesContainer = styled.div`
  position: relative;


`;

export const NotesCount = styled.p`
  position: absolute;
  margin-right: 3%;
  padding: 8px;
  bottom: 0;
  right: 0;
  font-size: 12px;
  background-color: #000000;
  border-radius: 18px;
`;


export const SidebarContainer = styled.div`


  a.ps-menu-button{

  }

  div.ps-sidebar-container.css-1yuvpyi {
  box-shadow: 0px 0px 11rem 4px #000000;
  opacity: 1;
}

`;


export const SidebarToggle = styled.button`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  border: none;
  background-color: transparent;
  padding: 15px;

  .react-icons{
    color:white;
  }
  svg {
  color: #F9F9F9;
  }

`;

export const StyledRange = styled(Form.Range)`
  /* Webkit thumb */
  &::-webkit-slider-thumb {
    background: #3b3d41 !important;
  }

  /* Firefox thumb */
  &::-moz-range-thumb {
    background: #3b3d41 !important;
  }

  /* IE thumb */
  &::-ms-thumb {
    background: #3b3d41 !important;
  }
`;

export const CardSegmentContainer = styled.div`
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 20px;
`;

export const StyledImage = styled.img`
  width: 100%;
  padding: 2px;
  margin-bottom: 2px;
  border-radius: 40px;
`;


