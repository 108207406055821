import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  ClearCard,
  ClearDropdown,
  Line,
  NotesContainer,
  NotesCount,
  ScrollableDropdownMenu,
  ToolBGContainer,
} from "../components/container.styles";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Notes, Sublabel, TabTitle } from "../components/text.styles";
import { supabase, useAuth } from "../components/client";
import { Logout } from "../components/logout";
import { ListenBG } from "../assets";
import Header from "../components/Header";
import { NormalButton } from "../components/button.styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { serverUrl } from "../components/config";
import { v4 as uuidv4 } from "uuid";
import Dropdown from "react-bootstrap/Dropdown";
import { ListenOutput } from "../components/misc.styles";
import { useRef } from "react";

const Listen = () => {
  const currentUser = useAuth();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [audio, setAudio] = useState("");

  function generateUUID() {
    return uuidv4();
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${serverUrl}/listen/synthesize`,
        {
          text: textareaValue,
          voices: title.toLowerCase(),
        },
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);
      setAudio(url);

      const fileName = 'audio_' + Date.now() +"_" + Math.random().toString(36).substring(2, 15);
  
      const { storageData, storageError } = await supabase.storage
        .from('audio')
        .upload('public/'+ fileName, blob, {
            contentType: 'audio/wav',
         });

      const { data: urlData, error: urlError } = await supabase
        .storage
        .from('audio')
        .getPublicUrl('public/'+fileName);

      const { data: insertData, error: insertError } = await supabase
        .from('generated_audio_tortoisetts')
        .insert([{
          id: generateUUID(),
          audio: urlData.publicUrl,
          user_id: currentUser.user.id,
          voice: title,
          content: textareaValue
        }]);

      if (storageError || urlError || insertError) {
        throw new Error(`Storage: ${storageError}, URL: ${urlError}, Insert: ${insertError}`);
      }


    } catch (error) {
      console.error(error);
      alert("There was an error generating the audio. Please try again later.");
    }
    setLoading(false);
  };


  const options = [
    { id: 0, text: "Random" },
    { id: 1, text: "Angie" },
    { id: 2, text: "Applejack" },
    //{ id: 3, text: "Cond_latent_example" },
    { id: 4, text: "Daniel" },
    { id: 5, text: "Deniro" },
    { id: 6, text: "Emma" },
    { id: 7, text: "Freeman" },
    { id: 8, text: "Geralt" },
    { id: 9, text: "Halle" },
    { id: 10, text: "JLaw" },
    { id: 11, text: "LJ" },
    { id: 12, text: "Mol" },
    { id: 13, text: "Myself" },
    { id: 14, text: "Pat" },
    { id: 15, text: "Pat2" },
    { id: 16, text: "Rainbow" },
    { id: 17, text: "Snakes" },
    { id: 18, text: "Tim_Reynolds" },
    { id: 19, text: "Tom" },
    { id: 20, text: "Train_Atkins" },
    { id: 21, text: "Train_Daws" },
    { id: 22, text: "Train_Dotrice" },
    { id: 23, text: "Train_Dreams" },
    { id: 24, text: "Train_Empire" },
    { id: 25, text: "Train_Grace" },
    { id: 26, text: "Train_Kennard" },
    { id: 27, text: "Train_Lecault" },
    { id: 28, text: "Train_Mouse" },
    { id: 29, text: "Weaver" },
    { id: 30, text: "William" },
  ];

  const [title, setTitle] = useState(options[0].text);
  // create a state variable to store the value of the textarea
  const [textareaValue, setTextareaValue] = useState("");

  const handleSelect = (eventKey, event) => {
    setTitle(event.target.text);
  };

  function handleTextareaChange(event) {
    // update the textarea's value in state
    setTextareaValue(event.target.value);
    setCount(event.target.value.length);
  }

  function handleTextareaPaste(event) {
    handleTextareaChange(event);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <ToolBGContainer
        color1={"rgba(89,89,89,0.3)"}
        color2={"rgba(47,46,44,0.8)"}
        img={ListenBG}
      >
        <Container>
          
          <Header />
          <Line />
          <Row>
            
            <Col>
              <ClearCard>
                <TabTitle>
                  Listen
                </TabTitle>
                <Sublabel>Experience a more natural reading of your content with our advanced text-to-speech technology. Let us bring your text to life with the most lifelike voice available.</Sublabel>
                <Form>
                  <Row>
                  <Col sm={6} style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
                  <label>Voice:</label>
                  </Col>
                  <Col sm={6} style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
                  <ClearDropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {title}
                    </Dropdown.Toggle>
                    <ScrollableDropdownMenu>
                      {options.map((option) => (
                        <Dropdown.Item key={option.id} eventKey={option.id}>
                          {option.text}
                        </Dropdown.Item>
                      ))}
                    </ScrollableDropdownMenu>
                  </ClearDropdown>
                  </Col>
                  </Row>
                </Form>
                <NotesContainer>
                    <Notes
                      rows={5}
                      onChange={handleTextareaChange}
                      maxLength={399}
                      onPaste={handleTextareaPaste}
                    />
                    <NotesCount>{count}/399</NotesCount>
                  </NotesContainer>
                <ClearCard> 
                 <Sublabel><li>It takes roughly 1-3 minutes to generate your audio.</li>
                 <li>Please do not leave the page while generating or your audio will be lost.</li></Sublabel>
                {count > 0 && (
                  <NormalButton
                    hidden={loading}
                    type="submit"
                    onClick={handleFormSubmit}
                  >
                    GENERATE
                  </NormalButton>
                )}
                {loading ? (
                 <ScaleLoader color="#fff" size={10} speedMultiplier="1" />
                ) : (
                  <>
                   {audio && <audio className="my-4 w-100" src={audio} controls />}
                  </>
                )}
                </ClearCard>
              </ClearCard>
            </Col>
           
          </Row>
        </Container>
      </ToolBGContainer>
    </motion.div>
  );
};

export default Listen;
