import React, { useState } from 'react';
import { Form } from 'react-bootstrap'
import { ClearCard } from '../container.styles';
import { NormalButton } from '../button.styles';
import axios from 'axios';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useSaveText } from '../SaveText';
import { serverUrl } from '../config';
import { Sublabel } from '../text.styles';


export const FantasyWorldGen = (props) => {
  const { onDataFromChild } = props;
  const [loading, setLoading] = useState(false);
  const { saveText } = useSaveText();

  async function handleFormSubmit(e) {
    console.log('clicked!');
    e.preventDefault();
    let formData = {};
    console.log(formData);
    setLoading(true);
    try {
      // make the POST request and wait for the response
      const res = await axios.post(`${serverUrl}/story/getFantasyWorld`, formData);

      // get the data from the response
      const data = res.data;

     // save the generated text
     const result = await saveText(data[0].Outputs[0].Generated, 'Fantasy World');
     console.log(result); 

      // pass the generated article to the parent component
      onDataFromChild(data[0].Outputs[0].Generated);
    } catch (error) {
      // handle any errors that occurred during the request
      console.error(error);
    } finally {
      // set loading to false when the request is complete
      setLoading(false);
    }

  }

  return (
    <ClearCard>
      <h3>FANTASY WORLDS</h3>
      <Sublabel>Generate some random fantasy world settings.</Sublabel>
      <NormalButton hidden={loading} type="submit" onClick={handleFormSubmit} >Write</NormalButton>
      {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}
    </ClearCard>
  )
}
