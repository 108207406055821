import { Button } from 'react-bootstrap';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import styled, { keyframes } from 'styled-components'

export const Pulse = keyframes`
 0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

export const NormalButton = styled.button`
   display: inline-block;
   padding: 24px 32px;
   border: 0;
   text-decoration: none;
   border-radius: 36px;
   background-color: rgba(255,255,255,0.1);
   border: 1px solid rgba(255,255,255,0.1);
   backdrop-filter: blur(30px);
   color: rgba(255,255,255,0.8);
   font-size: 14px;
   letter-spacing: 2px;
   cursor: pointer;
   text-transform: uppercase;

    :hover{
      background-color: rgba(255,255,255,0.2);
    }

    :disabled{
      background-color: rgba(0,0,0,0.1);
      color: rgba(255,255,255,0.2);
    }
`;


export const LogoutButton = styled.button`
        color: white;
        width:50px;
        height:50px;
        position: fixed;
        top: 10px;
        right: 20px;
        border-radius: 50%;
        background-color: transparent;
        /* Add this to your button tag */
        outline: none; /* Optional */
        border: 0;
       /* Prevent background color leak outs */
       -webkit-background-clip: padding-box; 
       -moz-background-clip:    padding; 
       background-clip:padding-box;
       z-index: 999;
       

`


export const HistoryButton = styled.button`
    padding: 24px 32px;
    color: rgba(255,255,255,1);
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 15px;
     border: none;
     background: #22666b;
     height: 20px;
     width: 90%;
     margin-bottom: 10px;
     margin-top: 10px;

    :hover{
      background-color: #113538;
    }
`;

export const ChallengeButton = styled(NormalButton)`
  background-color: rgb(24, 61, 113);
  color: white;
  :hover{
      background: rgb(24, 61, 113, 0.7) !important;
  }
`

export const StyledAudio = styled.audio`
  &::-webkit-media-controls-timeline {
    display: none;
  }
  &::-webkit-media-controls-volume-slider {
    display: none;
  }

`;

export const NavIcon = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #39abb2;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    z-index: 101;

  #nav-icon4 {
    cursor: pointer;
    display: block;
    height: 50%;
    position: relative;
    width: 50%;
    z-index: 999;
    right: 0;
    bottom: 0
  }

  #nav-icon4 span {
  background: #ffffff;
  display: block;
  height: 2px;
  position: absolute;
  width: 100%;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  top: 50%; /* Add this rule to vertically center the bars */
  left: 50%; /* Add this rule to horizontally center the bars */
  transform: translate(-50%, -50%); /* Add this rule to center the bars both horizontally and vertically */
}

  #nav-icon4 span:nth-child(1) {
    top: 0;
  }

  #nav-icon4 span:nth-child(2) {
    top: 18px;
  }

  #nav-icon4 span:nth-child(3) {
    top: 36px;
  }

  #nav-icon4.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
  }
`;

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #23656b;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: #2f8991;
  }
`;

export const Icon = styled(props => (props.istoggled ? <AiOutlineClose {...props} /> : <AiOutlineMenu {...props} />)).attrs(props => ({
  'data-istoggled': props.istoggled
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: #fff;
  transition: all 0.2s ease-in-out;
`;


export const AuthButton = styled.button`
   display: inline-block;
   padding: 24px 32px;
   border: 0;
   text-decoration: none;
   border-radius: 36px;
   background-color: transparent;
   border: 0px solid rgba(255,255,255,0.1);
   color: rgba(255,255,255,0.8);
   font-size: 14px;
   letter-spacing: 2px;
   cursor: pointer;
   text-transform: uppercase;

    :hover{
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de;
    }

    :disabled{
      background-color: rgba(0,0,0,0.1);
      color: rgba(255,255,255,0.2);
    }
`;

const ImageSelectButtonWrapper = styled.label`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 12%;
  background-color: #ccc;
  cursor: pointer;
  position: relative;
`;

const VideoPreview = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12%;
`;



export const MediaPreviewButton = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
`;

export const MediaPreviewContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  //border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
`;

export const MediaPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const MediaPreviewPlaceholder = styled.div`
  color: #6c757d;
  /* font-weight: bold; */
  font-size: 13px;
`;

export const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #ff0000;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
`;

export const AudioButton = styled(NormalButton)`
   padding: 12px 24px;
   font-size: 12px;
   background-color: rgba(130, 130, 255, 0.3);
border: 1px solid rgba(130, 130, 255, 0.1);

:hover {
    background-color: rgba(130, 130, 255, 0.4);
}

:disabled {
    background-color: rgba(0, 0, 200, 0.1);
    color: rgba(130, 130, 255, 0.5);
}

`;