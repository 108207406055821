import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { serverUrl } from '../config';
import { supabase, useAuth } from '../client';


export const handleAudioLengthChange = (index, audioLength, audioLengths, setAudioLengths) => {
    const newAudioLengths = [...audioLengths];
    newAudioLengths[index] = audioLength;
    setAudioLengths(newAudioLengths);
};



export const handlePlayPreview = async (previewUrl, audioRef) => {
    if (previewUrl) {
        try {
            audioRef.current.src = previewUrl;
            audioRef.current.load();
            audioRef.current.play();
        } catch (error) {
            console.error('Error playing voice preview:', error);
        }
    }
};

export const handleAudioChange = (index, audioUrl, segments, setSegments) => {
    const newSegments = [...segments];
    newSegments[index] = {
        ...newSegments[index],
        audio: audioUrl,
    };
    setSegments(newSegments);
};

export const handleShapeChange = (shape, setWidth, setHeight, setSelectedShape) => {
    setSelectedShape(shape);

    switch (shape) {
        case 'Square':
            setWidth(1080);
            setHeight(1080);
            break;
        case 'Landscape':
            setWidth(1980);
            setHeight(1080);
            break;
        case 'Portrait':
            setWidth(1080);
            setHeight(1980);
            break;
        default:
            setWidth(1080);
            setHeight(1080);
            break;
    }
};

export const handleTextChange = (index, newText, segments, setSegments) => {
    const newSegments = [...segments];
    newSegments[index].text = newText;
    setSegments(newSegments);
};




export const handleRemoveSegment = (index, segments, setSegments) => {
    console.log("Delete " + index)
    setSegments((prevSegments) => [
        ...prevSegments.slice(0, index),
        ...prevSegments.slice(index + 1),
    ]);
};


export const handleImageSelect = (index, image, segments, setSegments) => {
    const newSegments = [...segments];
    newSegments[index].image = image;
    setSegments(newSegments);
};

export const handleExport = async (segments, width, height, setLoading) => {

    setLoading(true);
    console.log({
        segments,
        width,
        height,
    });
    try {
        const response = await axios.post(`${serverUrl}/storyteller/render-video`, {
            segments: segments,
            width: width, // add this line
            height: height, // add this line
        });

        // Get the publicUrl from the server response
        const publicUrl = response.data.videoUrl.data.publicUrl;

        // Create a temporary link element to download the video
        const link = document.createElement('a');
        link.href = publicUrl;
        link.target = '_blank'; // Add this line to open the link in a new tab
        link.download = 'my-video.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.log(error);
        alert('Error exporting video');
    }

    setLoading(false);
};

export const uploadMedia = async (fileOrDataURI, bucketName, contentType) => {
    try {
        const fileName = uuidv4();
        const filePath = `media/${fileName}`;

        if (isDataURL(fileOrDataURI)) {
            const base64Content = fileOrDataURI.split(",")[1];
            const binaryContent = atob(base64Content);
            const buffer = new Uint8Array(binaryContent.length);

            for (let i = 0; i < binaryContent.length; i++) {
                buffer[i] = binaryContent.charCodeAt(i);
            }

            const { error: uploadError } = await supabase.storage
                .from(bucketName)
                .upload(filePath, buffer, { contentType });
            if (uploadError) throw uploadError;
        } else {
            const { error: uploadError } = await supabase.storage
                .from(bucketName)
                .upload(filePath, fileOrDataURI, { contentType });
            if (uploadError) throw uploadError;
        }
        const publicUrlObject = supabase.storage
            .from(bucketName)
            .getPublicUrl(filePath);

        return publicUrlObject.data.publicUrl;

    } catch (error) {
        console.error("Error uploading media:", error);
        alert("Error uploading media");
    }
};

// Helper function to determine content type from data URL
const getContentTypeFromDataURL = (dataURL) => {
    const header = dataURL.split(",")[0];
    const mimeType = header.match(/data:(.*?);/)[1];

    return mimeType;
};


function isDataURL(s) {
    const regex = /^\s*data:([a-zA-Z0-9-+.,]+\/[a-zA-Z0-9-+.,]+)?(?:;charset=([a-zA-Z0-9-+.,]+))?(?:;(base64))?,(.*)\s*$/;
    return !!s.match(regex);
}


export const saveProject = async (projectId, project_name, user_id, width, height, segments) => {
    try {
        // Save the project settings
        const { data: projectData, error: projectError } = await supabase
            .from('video_projects')
            .update({ project_name: project_name, user_id: user_id, width: width, height: height })
            .match({ id: projectId, user_id: user_id });

        console.log(user_id);

        if (projectError) throw projectError;

        // Save the segments
        for (const segment of segments) {
            let imageUrl = null;
            let audioUrl = null;

            if (segment.image) {
                if (isDataURL(segment.image)) {
                    const contentType = getContentTypeFromDataURL(segment.image);
                    imageUrl = await uploadMedia(segment.image, "segments", contentType);
                } else {
                    imageUrl = segment.image;
                }

                console.log("Uploading image:", imageUrl);
            }



            if (segment.audio) {
                audioUrl = segment.audio;
            }

            const segmentToSave = {
                video_project_id: projectId,
                segment_order: segment.id,
                text: segment.text,
                image_url: imageUrl,
                audio_url: audioUrl,
            };

            console.log(segmentToSave);

            const { error: segmentsError } = await supabase
                .from('segments')
                .upsert(segmentToSave, { on_conflict: { constraint: 'segments_pkey', update_columns: ['text', 'image_url', 'audio_url'] } });

            if (segmentsError) throw segmentsError;
        }

        alert('Project saved successfully');
    } catch (error) {
        console.error('Error saving project:', error);
        alert('Error saving project');
    }
};


export const loadProject = async (projectId, setProjectName, setWidth, setHeight, setSegments) => {
    try {
        // Load project settings
        const { data: projectData, error: projectError } = await supabase
            .from('video_projects')
            .select('*')
            .eq('id', projectId)
            .single();

        if (projectError) throw projectError;
        setWidth(projectData.width);
        setHeight(projectData.height);
        setProjectName(projectData.project_name);

        // Load segments
        const { data: segmentsData, error: segmentsError } = await supabase
            .rpc("get_segments", { project_id: projectId });

        console.log(projectId);


        if (segmentsError) {
            console.error('Error fetching segments:', segmentsError);
        } else {
            console.log('Segments data:', segmentsData);
        }

        const loadedSegments = segmentsData.map((segmentData) => ({
            id: segmentData.segment_order,
            text: segmentData.text,
            image: segmentData.image_url,
            audio: segmentData.audio_url,
        }));

        setSegments(loadedSegments);
    } catch (error) {
        console.error('Error loading project:', error);
        alert('Error loading project');
    }
};