import React, { createContext, useContext } from 'react';
import { supabase, useAuth } from './client';

const SaveTextContext = createContext();

export function SaveTextProvider(props) {
  const currentUser = useAuth();

  const { children } = props;
  const value = {
    saveText: async (generatedText, tool) => {
      const { data, error } = await supabase
        .from('generated_texts')
        .insert([
          { generated_text: generatedText, user_id: currentUser.user.id, tool: tool },
        ]);
      return { data, error };
    },
  };

  return <SaveTextContext.Provider value={value}>{children}</SaveTextContext.Provider>;
}

export function useSaveText() {
  const context = useContext(SaveTextContext);
  if (!context) {
    throw new Error('useSaveText must be used within a SaveTextProvider');
  }
  return context;
}