import React from 'react';
import { supabase, useAuth } from '../components/client';
import { AuthButton, NormalButton } from '../components/button.styles';
import { PopUp } from '../components/container.styles';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

const PasswordReset = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleClose = () => setShowForm(false);
  const handleSuccessClose = () => setSuccess(false);

  const onSubmit = async data => {
    console.log(data);
      try {
      const { error } = await supabase.auth.resetPasswordForEmail(data["email"]);
      if (error) throw error;
      setSuccess(true);
      setShowForm(false);
    } catch (error) {
      setError(error.message);
    } 
  };
  

  return (
    <>
      <AuthButton onClick={() => {
        console.log('clicked');
        setShowForm(true);
      }}>
        Forgot password?
      </AuthButton>
      <PopUp show={showForm} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reset Your Password</Modal.Title>
        </Modal.Header>
        <Form className="d-flex flex-column align-items-center mb-4">
          <Form.Group className="my-5">
            <Form.Label className="text-center mx-2">We'll send you a link to change your password.</Form.Label>
            <Form.Control type="email"  {...register("email", { required: true })} required={true} className="" placeholder="Enter your e-mail" />
          </Form.Group>
          {errors.email && <p className="text-center">This field is required</p>}
          {errors.email && <p className="text-center">{errors.email.message}</p>}
          <NormalButton type="submit" className="d-flex mx-auto" onClick={handleSubmit(onSubmit)}>Send reset link</NormalButton>
        </Form>

      </PopUp>

      {success &&
        <PopUp show={success} onHide={handleSuccessClose}>
          <p className='m-5'>An email with a password reset link has been sent to the provided email.</p>
        </PopUp>
      }
      {error &&
        <PopUp show={error} onHide={setError("")}>
          <p>An email with a password reset link has been sent to the provided email.</p>
        </PopUp>
      } 
  </>
  );
};

export default PasswordReset;
