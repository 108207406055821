import React from 'react';
import { TbLogout } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { LogoutButton } from './button.styles';
import { useAuth } from './client';

export const Logout = () => {
  const auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) return null;

  const handleClick = () => {
    console.log('Logout button was clicked!');
    auth.signOut();

    navigate(`/login`)
  }


  return (
    <LogoutButton onClick={handleClick}>
       <TbLogout size={30} />
    </LogoutButton>
  );
}
