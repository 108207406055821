import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ChallengeCol, ChallengeImage, ClearCard, CustomOverlayTag, Line, PopUp, ToolBGContainer } from '../components/container.styles';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { LogoText, Notes, SloganText, Sublabel, TabTitle } from '../components/text.styles';
import { supabase } from '../components/client';
import { useNavigate } from 'react-router-dom';
import { ChallengeBG } from '../assets';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { NormalButton } from '../components/button.styles';
import { IoSend } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const Challenge = () => {

  let navigate = useNavigate();

  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [actualPrompt, setActualPrompt] = useState("a portrait of an old doctor in 19th century, beautiful painting with highly detailed face by greg rutkowski and magali villanueve");
  const [score, setScore] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [image, setImage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [fetchingImage, setFetchingImage] = useState(false);

  const handleChange = (e) => {
    setInput(e.target.value);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // Calculate the Levenshtein distance between the input and the actual prompt
    const distance = levenshtein(input, actualPrompt);
    // Set the score based on the distance
    setScore((100 * (1 - distance / actualPrompt.length)).toFixed(1));
    setShowModal(false); 
    setLoading(false);
    // Set modal to visible
    setShowModal(true);
  };
  
  // Levenshtein distance function
  const levenshtein = (s1, s2) => {
    if (s1.length > s2.length) {
      [s1, s2] = [s2, s1];
    }
    const distances = new Array(s1.length + 1).fill(0).map((_, i) => i);
    for (let i = 1; i <= s2.length; i++) {
      let prevDistance = i - 1;
      let distance = i;
      for (let j = 1; j <= s1.length; j++) {
        if (s2[i - 1] === s1[j - 1]) {
          distance = prevDistance;
        } else {
          distance = 1 + Math.min(prevDistance, distance, distances[j]);
        }
        prevDistance = distances[j];
        distances[j] = distance;
      }
    }
    return distances[s1.length];
  };

  useEffect(() => {
    async function fetchAnImage() {
      const { data, error } = await supabase
        .from('random_image')
        .select('*')

      if (error) {
        console.error(error);
      }

      if (data) {
        setImage(data[0]);
        setActualPrompt(data[0].prompt);
      }

    }
    fetchAnImage();
  }, []);

  const fetchNewImage = async () => {
    setLoading(true);
    setShowModal(false);
    setFetchingImage(true); 
    const { data, error } = await supabase.from("random_image").select("*");
  
    if (error) {
      console.error(error);
      setLoading(false);
    }
  
    if (data) {
      setImage(data[0]);
      console.log(data);
      setActualPrompt(data[0].prompt);
      setInput("");
      setLoading(false);
    }
    setFetchingImage(false); // add this line
  };
  

  function handleImageClick() {
    setIsLightboxOpen(true);
  
    // Create an Image object to get the width and height of the image
    const img = new Image();
    img.src = image.image_url;
    img.onload = () => {
      console.log('Image loaded successfully');
      // Set the width and height state variables
      setWidth(img.width);
      setHeight(img.height);
    };
    img.onerror = (error) => {
      console.error('Error loading image:', error);
    };
  };
  
  
  const slides = [
    {
      src: image.image_url,
       width: width,
       height: height
    }
  ];

  console.log(slides);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
      <ToolBGContainer
        color1={"rgba(89,89,89,0.3)"}
        color2={"rgba(47,46,44,0.8)"}
        img={ChallengeBG}
      >
        <Container>
          
          <Row>
            <Col xs={12}>
              <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
              <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
              <Line className="my-3" />
            </Col>
          </Row>
          <Row>
            <Col>
              <ClearCard>
                <Row>
              <Col lg={5} style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
              <TabTitle>THE CHALLENGE</TabTitle>
               <Sublabel>Guess the prompt used to generate this image</Sublabel>
                    <Notes
                      placeholder="E.g.Titan,portrait,planets..."
                      onChange={(e) => handleChange(e)}
                      value={input}
                      rows={4}
                    />
                  <NormalButton style={{marginTop: "10px"}} hidden={!input || loading}  type="submit" onClick={handleFormSubmit}>
                      <IoSend /> Submit
                    </NormalButton>
                </Col>
                <Col lg={7}>

                {fetchingImage ? (
                  <ScaleLoader color="#fff" size={10} speedMultiplier="1" />
                ) : (
                  <ChallengeImage
                    src={image.image_url}
                    onClick={handleImageClick}
                  />
                )}
                
              </Col>
              </Row>
              </ClearCard>


              {isLightboxOpen && (
                <Lightbox
                  open={isLightboxOpen}
                  close={() => setIsLightboxOpen(false)}
                  animation={{ zoom: 400 }}
                  zoom={{
                    maxZoomPixelRatio: 2,
                    zoomInMultiplier: 2,
                    doubleTapDelay: 300,
                    doubleClickDelay: 500,
                    doubleClickMaxStops: 2,
                    keyboardMoveDistance: 50,
                    wheelZoomDistanceFactor: 100,
                    pinchZoomDistanceFactor: 100,
                    scrollToZoom: true
                  }}
                  startIndex={0}
                  plugins={[Zoom, Fullscreen]}
                  slides={slides} 
                />
              )}

            </Col>

            <PopUp style={{ fontFamily: 'Comfortaa', justifyContent: 'center', alignItems: 'center' }} show={showModal} onHide={() => setShowModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Your score is {score}%</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                <img className='w-50 rounded' alt='' src={image.image_url} />
                <h6 className='p-3'><b>You put :<br /></b><CustomOverlayTag style={{ fontSize: '15px' }}>{input}</CustomOverlayTag></h6>
                <h6 className='p-3'><b>The actual prompt was :</b><CustomOverlayTag style={{ fontSize: '15px' }}>{actualPrompt}</CustomOverlayTag></h6>
              </Modal.Body>
              <Modal.Footer>
                <NormalButton variant="danger" onClick={fetchNewImage} disabled={fetchingImage}>
                  Try Again
                </NormalButton>
              </Modal.Footer>

            </PopUp>
          </Row>
        </Container>
      </ToolBGContainer>
    </motion.div>
  )
}

export default Challenge