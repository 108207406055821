
import axios from 'axios';
import { supabase } from '../client';
import { serverUrl } from '../config';
import { v4 as uuidv4 } from 'uuid';



export function generateUUID() {
    return uuidv4();
}

export async function saveImageToGallery(imageUrl, imageId) {
    try {
        // Fetch the image from the Express endpoint using axios
        const response = await axios.get(`${serverUrl}/art/fetch-image`, {
            params: {
                imageUrl: imageUrl
            },
            responseType: 'blob'
        });
        // Convert the image data to a Blob object
        const blob = response.data;
        console.log(imageId);

        // Upload the Blob object to supabase storage
        const { storageData, storageError } = await supabase
            .storage
            .from('gallery')
            .upload('public/' + imageId, blob);
        if (storageError) {
            console.error(storageError);
            throw new Error(storageError);
        } else {
            console.log(storageData);
            return storageData;
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export async function getImageUrlFromStorage(imageId) {
    console.log(imageId)
    try {
        const urlData = supabase
            .storage
            .from('gallery')
            .getPublicUrl('public/' + imageId);
        return urlData;
    } catch (error) {
        console.error(error);
    }
}

export async function saveImageToDB(imageId, data, currentUser) {
    // Get the public URL of the uploaded image
    const imageUrl = await getImageUrlFromStorage(imageId);
    console.log(imageUrl.data.publicUrl);

    // Save the image URL and other metadata to the database
    const insertData = {
        uuid: generateUUID(),
        userid: currentUser.user.id,
        image_url: imageUrl.data.publicUrl,
        date_generated: new Date(),
        prompt: data.prompt,
        privacy: 'public',
        width: data.width,
        height: data.height,
        guidance: data.guidance,
        seed: data.seed,
        prompt_strength: data.strength,
        negative_prompt: data.negative,
        num_inference_steps: data.steps,
        scheduler: data.scheduler
    };
    const { insertedData } = await supabase
        .from('images_sd_1.5_replicate')
        .insert([insertData]);
    return insertedData;
} 