import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { StoryBookMediaPreview, StoryBookMediaPreviewButton, StoryBookMediaPreviewContainer, StoryBookMediaPreviewImage, StoryBookMediaPreviewPlaceholder, StorybookAudio, StorybookButton, StorybookCard, StorybookInput, StorybookLabel } from './storybook.styles';
import { BsCamera, BsCameraFill, BsFillCameraReelsFill, BsMusicNote } from 'react-icons/bs';
import { BiTrash, BiUpArrowAlt, BiDownArrowAlt, BiPlus } from 'react-icons/bi';
import { Sublabel } from '../text.styles';
import { MediaPreview, MediaPreviewButton, MediaPreviewContainer, MediaPreviewPlaceholder } from '../button.styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClearDropdown, ScrollableDropdownMenu } from '../container.styles';
import ScaleLoader from 'react-spinners/ScaleLoader';

const SegmentCard = ({ index, segment, updateSegment, addSegmentAbove, addSegmentBelow, removeSegment, canRemove, generateAudio, voiceList, loading, setLoading }) => {
    const [text, setText] = useState(segment.text);
    const [media, setMedia] = useState(null);
    const [audio, setAudio] = useState(null);
    const [voice, setVoice] = useState(segment.voice || (voiceList.length > 0 ? voiceList[0] : ''));
    const [duration, setDuration] = useState(segment.duration || 0);
    
    useEffect(() => {
        if (segment.media) {
            setMedia(segment.media);
        } else {
            setMedia(null);
        }
    }, [segment.media]);



    useEffect(() => {
        setText(segment.text);
        setVoice(segment.voice || (voiceList.length > 0 ? voiceList[0] : ''));
        setAudio(segment.audio || null);
        setDuration(segment.duration || '');
    }, [segment, voiceList]);



    const handleMediaChange = (event) => {
        const file = event.target.files[0];
        const objectURL = file ? URL.createObjectURL(file) : null;
        const newSegment = { ...segment, media: objectURL };
        updateSegment(index, newSegment);
        setMedia(objectURL);
    };

    const handleVoiceChange = (selectedVoiceId) => {
        const selectedVoice = voiceList.find((voice) => voice.voice_id === selectedVoiceId);
        const newSegment = { ...segment, voice: selectedVoice };
        updateSegment(index, newSegment);
        setVoice(selectedVoice);
    };





    const handleTextChange = (event) => {
        const newText = event.target.value;
        const newSegment = { ...segment, text: newText };
        updateSegment(index, newSegment);
        setText(newText);
    };


    return (
        <StorybookCard>
            <Row className="align-items-center">
                <Row className="align-items-center justify-content-between">
                <Col xs={3} className="d-flex justify-content-center">
                        <StorybookLabel>Card {`${index + 1}`}:</StorybookLabel>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-center">
                        <ClearDropdown onSelect={handleVoiceChange}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {voice ? voice.name : 'Select Voice'}
                            </Dropdown.Toggle>

                            <ScrollableDropdownMenu>
                                {voiceList.map((voice) => (
                                    <Dropdown.Item key={voice.voice_id} eventKey={voice.voice_id}>
                                        {voice.name}
                                    </Dropdown.Item>
                                ))}
                            </ScrollableDropdownMenu>
                        </ClearDropdown>
                    </Col>
                    <Col xs={5} className="d-flex justify-content-center">
                        <audio
                            key={audio || segment.audio || ""}
                            id={`audio-${index}`}
                            src={audio || segment.audio || ""}
                            controls
                            style={{
                                width: "100%",
                                height: "32px",
                                background: "#f0f0f0",
                                borderRadius: "4px",
                                outline: "none",
                            }}
                            hidden={!audio && !segment.audio}
                        >
                            <source src={audio || segment.audio || ""} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>

                    </Col>
                </Row>

                <Col>
                    <Row>
                        <Col xs={2}>
                            <Form.Group controlId={`media-${index}`} className="mb-3">
                                <StoryBookMediaPreviewButton>
                                    <input
                                        type="file"
                                        id={`media-upload-${index}`}
                                        accept="image/*,video/*,.gif"
                                        onChange={handleMediaChange}
                                        style={{ display: 'none' }}
                                    />

                                    <StoryBookMediaPreviewContainer htmlFor={`media-upload-${index}`}>
                                        {media ? (
                                            <StoryBookMediaPreview src={media} />
                                        ) : segment.media ? (
                                            <StoryBookMediaPreview src={segment.media} />
                                        ) : (
                                            <StoryBookMediaPreviewPlaceholder><BsFillCameraReelsFill /></StoryBookMediaPreviewPlaceholder>
                                        )}
                                    </StoryBookMediaPreviewContainer>

                                </StoryBookMediaPreviewButton>
                            </Form.Group></Col>
                        <Col xs={10}>
                            <StorybookInput type="textarea" value={text} onChange={handleTextChange} />

                        </Col>

                    </Row>
                </Col>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <StorybookButton disabled={loading} hidden={!text} onClick={() => generateAudio(index, segment, setLoading)} style={{ marginRight: '8px' }}>
                            {loading ? <ScaleLoader color='white' height={12} width={2} speedMultiplier="2.3" /> :  <BsMusicNote />   }

                    </StorybookButton>
                    <StorybookButton onClick={() => addSegmentAbove(index)} style={{ marginRight: '8px' }}>
                        <BiPlus /> / <BiUpArrowAlt />
                    </StorybookButton>
                    <StorybookButton onClick={() => addSegmentBelow(index)} style={{ marginRight: '8px' }}>
                        <BiPlus /> / <BiDownArrowAlt />
                    </StorybookButton>
                    {canRemove && (
                        <StorybookButton onClick={removeSegment} style={{ marginRight: '8px' }}>
                            <BiTrash />
                        </StorybookButton>
                    )}

                </div>
            </Row>
        </StorybookCard >
    );
};

export default SegmentCard;
