import React from 'react'
import PropagateLoader  from "react-spinners/PropagateLoader";
import { ToolBGContainer } from '../components/container.styles';
import "../App.css"

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

const Loading = () => {
  return (
    <ToolBGContainer
    color1={"rgba(78,60,42,0.6)"}
    color2={" rgba(155,81,62,0.1)"}
    img={"https://firebasestorage.googleapis.com/v0/b/fictioneer-c53cf.appspot.com/o/Assets%2Ffictioneer-loading.png?alt=media&token=3654bcaf-828f-46eb-927e-890ec9eca5bf"}
    >
      <PropagateLoader style={style} color="#fff" size={20} speedMultiplier="1"/>
    </ToolBGContainer>
  )
}

export default Loading