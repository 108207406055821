import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { serverUrl } from '../config';

const SuccessNotiStyle = {
    fontFamily: "Comfortaa, sans-serif",
    fontSize: "16px",
    background: "linear-gradient(to right, #0b5550, #0b5550)",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    padding: "16px"
}

const ErrorNotiStyle = {
    fontFamily: "Comfortaa, sans-serif",
    fontSize: "16px",
    background: "linear-gradient(to right, #7e0000, #a40000)",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    padding: "16px",
    backdropFilter: " blur(30px)"
}

const useSegments = (initialSegments, voiceList) => {
    const [segments, setSegments] = useState(initialSegments);
    console.log(segments);

    const addSegment = () => {
        setSegments([
            ...segments,
            {
                text: segments.length > 0 ? segments[segments.length - 1].text : '',
                audio: null
            }
        ]);
    };

    const addSegmentAbove = (index) => {
        const newSegments = [...segments];
        const defaultVoice = voiceList.length > 0 ? voiceList[0] : '';
        const newSegment = { text: "", audio: "", media: null, voice: defaultVoice, duration: 3 };
        newSegments.splice(index, 0, newSegment);
        setSegments(newSegments);
        toast.success(`Successfully added segment above!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                fontFamily: "Comfortaa, sans-serif",
                fontSize: "16px",
                background: "linear-gradient(to right, #0b5550, #0b5550)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: "16px"
            },
        });

    };

    const addSegmentBelow = (index) => {
        const newSegments = [...segments];
        const defaultVoice = voiceList.length > 0 ? voiceList[0] : '';
        const newSegment = { text: "", audio: "", media: null, voice: defaultVoice, duration: 3 };
        newSegments.splice(index + 1, 0, newSegment);
        setSegments(newSegments);
        toast.success(`Successfully added segment below!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                fontFamily: "Comfortaa, sans-serif",
                fontSize: "16px",
                background: "linear-gradient(to right, #0b5550, #0b5550)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: "16px"
            },
        });
    };


    const removeSegment = (index) => {
        const newSegments = [...segments];
        newSegments.splice(index, 1);
        setSegments(newSegments);

        toast.success(`Successfully removed segment!`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                fontFamily: "Comfortaa, sans-serif",
                fontSize: "16px",
                background: "linear-gradient(to right, #0b5550, #0b5550)",
                boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: "16px"
            },
        });
    };

    const updateSegment = (index, newSegment, media) => {
        const newSegments = [...segments];
        newSegments[index] = {
            ...newSegment,
            media: media ? URL.createObjectURL(media) : newSegment.media,
            voice: newSegment.voice,
            duration: newSegment.duration,
        };
        setSegments(newSegments);
    };



    const generateAudio = async (index, segment, setLoading) => {
        console.log(segment)
        let voiceId = segment.voice.voice_id;
        let text = segment.text;
        let formData = { text, voiceId };

        setLoading(true);

        try {
            // Make the request to the /generate_tts endpoint
            const response = await axios.post(`${serverUrl}/storyteller/generate_tts_and_word_timings`, formData, {
                responseType: 'json',
            });
            const responseData = response.data;

            // Convert base64 string to byte array
            const byteCharacters = atob(responseData.audio);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob from the byte array
            const audioBlob = new Blob([byteArray], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.onloadedmetadata = () => {
                const audioDuration = audio.duration;

                // Update the segment with the new audio URL, duration, and timings
                const newSegment = {
                    ...segment,
                    audio: audioUrl,
                    duration: audioDuration,
                    timings: responseData.wordTimings, // Note the change here
                };
                updateSegment(index, newSegment);

                console.log('Generating audio');
                toast.success(`Successfully generated Audio!`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: SuccessNotiStyle,
                });
            };

            setLoading(false);
        } catch (error) {
            console.error('Failed to generate Audio!', error);
            toast.error(`Failed to generate Audio!`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: ErrorNotiStyle,
            });
            setLoading(false);
        }
    };





    return {
        segments,
        addSegment,
        addSegmentAbove,
        addSegmentBelow,
        removeSegment,
        updateSegment,
        generateAudio,
    };

};

export default useSegments;
