import axios from 'axios';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Alert, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { ArtBG } from '../assets';
import { NormalButton } from '../components/button.styles';
import { useAuth } from '../components/client';
import { ClearCard, Line, MyTab, MyTabs, ToolBGContainer } from '../components/container.styles';
import { LogoText, Notes, SloganText, Sublabel } from '../components/text.styles';
import { serverUrl } from '../components/config';
import 'react-tooltip/dist/react-tooltip.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import { RikuStableDiffusion } from '../components/art-models/RikuStableDiffusion';
import { GenerateForm } from '../components/art-models/GenerateForm';
import artModels from '../components/art-models/art-models.json';
import { saveImageToGallery, saveImageToDB, generateUUID } from '../components/art-models/imageUtils';

const Art = () => {

  // Get the current user using the useAuth custom hook
  const currentUser = useAuth();
  // Get the navigate function from the useNavigate hook provided by react-router-dom
  let navigate = useNavigate();
  // Initialize state variables
  const [promptLoading, setPromptLoading] = useState(false);
  const [image, setImage] = useState(ArtBG);
  const [isLightboxOpen, setIsLightboxOpen] = useState();
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [topic, setTopic] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // handleLoading function is used to update the loading state
  function handleLoading(data) {
    console.log(data);
    setLoading(data);
  }

  // handleDataFromChild function receives data from a child component
  function handleDataFromChild(data) {
    console.log(data);
    // do something with the data passed from the child component
    setImage(data);
  }

  // handleTopicChange function updates the topic state when a new topic is selected
  const handleTopicChange = (e) => {
    setTopic(e.target.value)
  }

  // onSubmit function is called when the form is submitted
  async function onSubmit(data) {
    // Check if image dimensions are within allowed limits
    if ((data.width > 1024 && data.height > 768) || (data.width > 768 && data.height > 1024)) {
      setErrorMessage('The maximum size allowed is 1024x768 or 768x1024');
      setError(true);
    } else {
      setLoading(true);
      setError(false);
      const openjourneyModel = artModels[0].version;
      let promptValue = data.prompt;
      if (data.version === openjourneyModel) {
        promptValue = 'mdjrny-v4 style ' + data.prompt;
      }

      // Make a request to the server to generate the art
      try {
        const res = await axios.post(`${serverUrl}/art/generateStableDiffusion`, {
          input: promptValue,
          guidance: data.guidance_scale,
          width: data.width,
          height: data.height,
          seed: data.seed,
          strength: data.strength,
          negative: data.negative,
          inference_steps: data.num_inference_steps,
          scheduler: data.scheduler,
          version: data.version,
          model: data.model
        });

        const imageData = res.data;

        // Generate a random ID and set it
        const imageId = generateUUID();

        // Save the image to the gallery
        await saveImageToGallery(imageData, imageId);

        // Save the image URL and other metadata to the database
        await saveImageToDB(imageId, data, currentUser);

        setImage(imageData)
        console.log(imageData);

      } catch (error) {
        console.error(error);
        setError(true);
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  // handleGeneratePrompt function makes a request to the server to generate a prompt
  async function handleGeneratePrompt() {
    let formData = { topic: topic };
    setPromptLoading(true);
    try {
      // make the POST request and wait for the response
      const res = await axios.post(`${serverUrl}/art/generatePrompt`, formData);

      // get the data from the response
      const data = res.data;

      setGeneratedPrompt(data[0].Outputs[0].Generated);
      console.log(data[0].Outputs[0].Generated);

    } catch (error) {
      // handle any errors that occurred during the request
      console.error(error);
      setErrorMessage(error.message);
      setError(true);
    } finally {
      // set loading to false when the request is complete
      setPromptLoading(false);
    }
  }

  // handleImageClick function sets the lightbox state to open when the image is clicked
  function handleImageClick() {
    setIsLightboxOpen(true);
  }
  // Define the slides for the lightbox
  const slides = [
    {
      src: image,
      width: null,
      height: null
    }
  ];

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
      <ToolBGContainer
        color1={"rgba(8, 6, 36,0.7)"}
        color2={"rgba(221, 114, 83,0.4)"}
        img={ArtBG}
        className='p-0'
      >
        <Container>
          <Row>
            <Col>
              <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
              <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
            </Col>
            <Line className="mt-3" />
          </Row>

          <Row>
            <Col>

              <ClearCard >

                <h2 style={{ fontWeight: "bold" }}>ART CREATOR</h2>
                {error && <Alert variant="danger">Error: {errorMessage}</Alert>}
                <MyTabs
                  defaultActiveKey="1"
                  id="tool-tabs"
                  className="mb-3"
                  variant="pills"
                >

                  <MyTab eventKey="1" title="Quick SD (V1.5)" disabled={loading}>
                    <RikuStableDiffusion onDataFromChild={handleDataFromChild} loadingStatus={handleLoading} />
                  </MyTab>
                  <MyTab eventKey="2" title="SD (V1.5)" disabled={loading}>
                    <GenerateForm config={artModels[2]} version={artModels[2].version} model={artModels[2].model} onFormSubmit={onSubmit} loading={loading} />
                  </MyTab>
                  <MyTab eventKey="3" title="SD (V2.1)" disabled={loading}>
                    <GenerateForm config={artModels[3]} version={artModels[3].version} model={artModels[3].model} onFormSubmit={onSubmit} loading={loading} />
                  </MyTab>
                  <MyTab eventKey="4" title="Openjourney" disabled={loading}>
                    <GenerateForm config={artModels[0]} version={artModels[0].version} model={artModels[0].model} onFormSubmit={onSubmit} loading={loading} />
                  </MyTab>
                  <MyTab eventKey="5" title="Anything v4 (Anime)" disabled={loading}>
                    <GenerateForm config={artModels[1]} version={artModels[1].version} model={artModels[1].model} onFormSubmit={onSubmit} loading={loading} />
                  </MyTab>
                </MyTabs>
              </ClearCard>

            </Col>
            <Col lg={6} >
              <ClearCard style={{ "display": "flex", alignItems: "center" }}>
                <h2 style={{ fontWeight: "bold" }}>YOUR ARTWORK</h2>
                <Image className="w-100 p-2" style={{ borderRadius: "40px" }} onClick={handleImageClick} src={image}></Image>
              </ClearCard>

              {isLightboxOpen && (
                <Lightbox
                  open={isLightboxOpen}
                  close={() => setIsLightboxOpen(false)}
                  animation={{ zoom: 400 }}
                  zoom={{
                    maxZoomPixelRatio: 2,
                    zoomInMultiplier: 2,
                    doubleTapDelay: 300,
                    doubleClickDelay: 500,
                    doubleClickMaxStops: 2,
                    keyboardMoveDistance: 50,
                    wheelZoomDistanceFactor: 100,
                    pinchZoomDistanceFactor: 100,
                    scrollToZoom: true
                  }}
                  startIndex={0}
                  plugins={[Zoom, Fullscreen]}
                  slides={slides}
                />
              )}

              <Row>
                <Col >
                  <ClearCard>
                    <h2 style={{ fontWeight: "bold" }}>PROMPT EXPANDER</h2>
                    <Sublabel>Give it a subject and get a perfect prompt to go!</Sublabel>
                    <Notes value={topic} onChange={handleTopicChange} rows={1} />
                    <NormalButton className="my-3" disabled={!topic} hidden={!topic || promptLoading} onClick={handleGeneratePrompt} type="submit" >Generate</NormalButton>
                    {promptLoading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}
                    <Notes hidden={!generatedPrompt} className="my-3 text-center" value={generatedPrompt} rows={5} readOnly />

                  </ClearCard>
                </Col>
              </Row>

            </Col>

          </Row>

        </Container>
      </ToolBGContainer>
    </motion.div>
  )
}

export default Art