import { AbsoluteFill, Audio, Sequence, useVideoConfig } from 'remotion';
import { TextComponent } from './Components/TextComponent';
import { MediaComponent } from './Components/MediaComponent';

export const MyDynamicVideo = ({ projectSettings, segments }) => {
  const { width, height, fps } = useVideoConfig();

  const renderSegment = (segment, index, durationInFrames) => {
    if (segment.text) {
      return <TextComponent settings={projectSettings} key={index} text={segment.text} durationInFrames={durationInFrames} wordTimings={segment.wordTimings} />;
    } else if (segment.media) {
      return <MediaComponent settings={projectSettings} key={index} media={segment.media} durationInFrames={durationInFrames} />;
    }
  };

  let currentFrame = 0;

  return (
    <AbsoluteFill>
      {projectSettings.backgroundMusic && (
        <Audio src={projectSettings.backgroundMusic} volume={projectSettings.bgmVolume} />
      )}
      {segments.map((segment, index) => {
        const durationInFrames = Math.max(1, Math.ceil(segment.duration * fps));
        const startFrame = currentFrame;
        currentFrame += durationInFrames;

        return (
          <Sequence key={index} from={startFrame} durationInFrames={durationInFrames}>
            {segment.media ? (
              <MediaComponent settings={projectSettings} media={segment.media} />
            ) : (
              <AbsoluteFill style={{ backgroundColor: 'white' }} />
            )}
            {segment.text && renderSegment(segment, index, durationInFrames)}
            {segment.audio && <Audio src={segment.audio} />}
          </Sequence>
        );
      })}
    </AbsoluteFill>
  );



};
