import React, { useEffect } from 'react';
import { useCurrentFrame, useVideoConfig } from 'remotion';
import { interpolate } from 'remotion';

export const TextComponent = ({ text, settings, durationInFrames, wordTimings }) => {
  const {
    font,
    fontSize,
    subtitleColor,
    subtitleBackground,
    subtitlePosition,
    subtitleDisplay,
    enableFadeBetweenSegments,
  } = settings;
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const getTextOpacity = (frameInSegment, currentSegmentDuration) => {
    const fadeInEnd = 30;
    const fadeOutStart = currentSegmentDuration - 30;

    if (frameInSegment <= fadeInEnd) {
      return interpolate(frameInSegment, [0, fadeInEnd], [0, 1]);
    } else if (frameInSegment >= fadeOutStart) {
      return interpolate(
        frameInSegment,
        [fadeOutStart, currentSegmentDuration],
        [1, 0]
      );
    } else {
      return 1;
    }
  };



  const getVisibleWords = (wordTimings, frame, fps, mode) => {
    if (!Array.isArray(wordTimings)) {
      return '';
    }

    console.log("Check here----->",mode)

    const currentSecond = frame / fps;

    if (mode === 'Word') {
      return wordTimings
        .filter(({ start, end }) => start <= currentSecond && end >= currentSecond)
        .map(({ word }) => word)
        .join(' ');
    }

    if (mode === 'Sequence') {
      return wordTimings
        .filter(({ start }) => start <= currentSecond)
        .map(({ word }) => word)
        .join(' ');
    }

    if (mode === 'Phrase') {
      const currentWordIndex = wordTimings.findIndex(({ start, end }) => start <= currentSecond && end >= currentSecond);
      return wordTimings
        .slice(currentWordIndex, currentWordIndex + 3)
        .map(({ word }) => word)
        .join(' ');
    }

    // For 'Sentence' and 'Full', return the entire text for now
    return text;
  };

  const getVisibleText = (frame, fps, mode) => {
    if (wordTimings && Array.isArray(wordTimings)) {
      return getVisibleWords(wordTimings, frame, fps, mode);
    } else {
      return text;
    }
  };

  const visibleText = getVisibleText(frame, fps, subtitleDisplay);

  const opacity = enableFadeBetweenSegments
    ? getTextOpacity(frame, Math.ceil(durationInFrames))
    : 1;

  const textStyle = {
    fontFamily: font,
    fontSize: `${fontSize}px`,
    color: subtitleColor,
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: opacity,
    top: subtitlePosition === 'center' ? 0 : subtitlePosition === 'top' ? 80 : 'initial',
    bottom: subtitlePosition === 'center' ? 0 : subtitlePosition === 'bottom' ? 80 : 'initial',
  };

  const spanStyle = {
    backgroundColor: subtitleBackground,
    borderRadius: '8px',
    padding: '4px 8px',
  };


  return (
    <div style={textStyle}>
      <span style={spanStyle}>{visibleText}</span>
    </div>
  );
};
