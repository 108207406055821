import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Line, ToolBGContainer } from '../components/container.styles';
import { LogoText, SloganText } from '../components/text.styles';
import { motion } from 'framer-motion';
import { DashboardBG, ArticleBG, FictionBG, ArtBG, ChallengeBG, HistoryBG, ListenBG, StorytellerBG } from '../assets';
import DashboardCard from '../components/DashboardCard';
//import { DashboardBG, ArticleBG, FictionBG, ArtBG, ChatBG, EditorBG, AskBG, ChallengeBG, HistoryBG, ListenBG, ExplainBG } from '../assets';

const Dashboard = () => {

  let navigate = useNavigate();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .2 }}>
      <ToolBGContainer
        color1={"rgba(2,3,10,0.4)"}
        color2={"rgba(47,46,44,0.9)"}
        img={DashboardBG}
      >
        <Container>
          <Row className="mb-5">
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
              <SloganText onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
            </Col>
            <Line className="mt-3" />
          </Row>
          <Row>
            <DashboardCard page="/article" image={ArticleBG} title="Article" subtitle="Write your next article in minutes" />
            <DashboardCard page="/story" image={FictionBG} title="Story" subtitle="Write your short story in minutes" />
            <DashboardCard page="/art" image={ArtBG} title="Art" subtitle="Generate your artwork in seconds" />
            <DashboardCard page="/challenge" image={ChallengeBG} title="Challenge" subtitle="Guess the concept behind the art" />
            <DashboardCard page="/history" image={HistoryBG} title="History" subtitle="Find all your previous generations here" />
            <DashboardCard page="/listen" image={ListenBG} title="Listen" subtitle="Hear your content read out to you by the AI" />
            <DashboardCard page="/storyteller" image={StorytellerBG} title="Storyteller" subtitle="Create a storytime video for your stories" />
            {/* <DashboardCard page="/chat" image={ChatBG} title="Chat" subtitle="Talk to an AI"/>
          <DashboardCard page="/editor" image={EditorBG} title="Editor" subtitle="AI-powered text generator for long form content"/>
          <DashboardCard page="/ask" image={AskBG} title="Ask The Fictioneer" subtitle="Ask whatever you want and get a response from the AI"/>
          <DashboardCard page="/explain" image={ExplainBG} title="Explain" subtitle="Have the AI explain your content"/> */}

          </Row>
        </Container>


      </ToolBGContainer>
    </motion.div>
  )
}

export default Dashboard

