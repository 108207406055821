import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthButton, NormalButton } from '../components/button.styles';
import { AuthCard, ClearCard, Line, PopUp, ToolBGContainer } from '../components/container.styles';
import { Logout } from '../components/logout';
import { LogoText, MyLink, RedirectLabel, SloganText } from '../components/text.styles';
import { useAuth } from '../components/client'
import PasswordReset from '../components/ForgetPassword';
import { useForm } from "react-hook-form";
import { LoginBG } from '../assets';
import ScaleLoader from 'react-spinners/ScaleLoader';


const Login = () => {

  let navigate = useNavigate();
  const { signIn } = useAuth();

  //State for disabling sign-up button once pressed
  const [loading, setLoading] = useState(false);
  const currentUser = useAuth();
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const { register, handleSubmit, watch, formState: { errors } } = useForm();


  const onSubmit = async data =>{
    // set the loading state to true to show the loading indicator
    setLoading(true);
    console.log(data.email);


    try {
    const {error } = await signIn({ email: data.email, password: data.password });
      if (error) throw error
      } catch (error) {
        setError(error.message);
        handleShow();
        //alert(error.error_description || error.message)
      } finally {
        setLoading(false)
      }

  };


  console.log(watch("example")); // watch input value by passing the name of it


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      
      <ToolBGContainer
        color1={"rgba(40,10,12,0.4)"}
        color2={"rgba(25,11,18,0.9)"}
        img={LoginBG}
      >
        <Container>
          <Row>
            <Col>
              <LogoText onClick={() => navigate(`/dashboard`)}>FICTIONEER</LogoText>
              <SloganText className="pb-3" onClick={() => navigate(`/dashboard`)}>Write your story, your way.</SloganText>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <ClearCard>
                <Col md={8} lg={8}>
                  <h3>SIGN IN</h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                    <Form.Label>Email:</Form.Label>
                     <Form.Control type="email"  {...register("email",{ required: true })} />
                     {errors.email && <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>


                    <Form.Group className="mb-3">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control type="password" {...register("password", { required: true })} /> 
                    {errors.password && <p className="text-white bg-danger rounded p-1">This field is required</p>}
                    </Form.Group>

                    
                    
                    <NormalButton hidden={loading} type="submit" onClick={handleSubmit}>SIGN IN</NormalButton>
                    {loading && <ScaleLoader color="#fff" size={10} speedMultiplier="1" />}


                    <PopUp show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Sign In Error</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{error}</Modal.Body>
                      <Modal.Footer>
                        <NormalButton variant="secondary" onClick={handleClose}>
                          Close
                        </NormalButton>
                      </Modal.Footer>
                    </PopUp>
                  </form>

                  <Line className='mt-4' />
                  <Row className='extra-btns d-flex flex-direction-row align-items-baseline'>
                      <Col>
                        <AuthButton className="mt-4" type="link" onClick={() => navigate("/register")}>
                         No Account?
                        </AuthButton>
                      </Col>
                      <Col>
                     <PasswordReset />

                      </Col>
                    </Row>
                </Col>
              </ClearCard>
            </Col>
          </Row>
        </Container>
      </ToolBGContainer>
    </motion.div>
  )
}

export default Login