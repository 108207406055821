import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ThumbnailImageProps } from "react-grid-gallery";

const ImageComponent = (props) => {
  const [show, setShow] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    });
    observer.observe(imgRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  if (show) {
    return <img style={{borderRadius:"28px"}} {...props.imageProps} ref={imgRef} />;
  }

  return <div style={{ ...props.imageProps.style, height: props.imageProps.height, width: props.imageProps.width }} ref={imgRef} />;
};

ImageComponent.propTypes = {
  imageProps: PropTypes.object.isRequired
};

export default ImageComponent;
