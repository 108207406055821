import React, { useEffect, useState } from 'react';
import { useCurrentFrame, useVideoConfig } from 'remotion';
import { spring } from 'remotion';

export const MediaComponent = ({ media, settings }) => {
  const [zoom, setZoom] = useState(false);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setZoom(settings.enableImageZoom === 'true');
    setFade(settings.enableFadeBetweenSegments === 'true');
  }, [settings]);

  useEffect(() => {
    if (fade) {
      setTimeout(() => setFade(false), 1000);
    }
  }, [fade]);

  const frame = useCurrentFrame();
  const { fps, durationInFrames } = useVideoConfig();
  const progress = frame / durationInFrames;

  const zoomFactor = spring({
    frame: progress * durationInFrames,
    fps,
    config: {
      stiffness: 0.1,
      damping: 100,
    },
  });

  const scale = zoom ? 1 + zoomFactor * 0.2 : 1;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${media})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: fade ? 0.8 : 1,
        transition: `opacity 1s`,
        transform: `scale(${scale})`,
      }}
    />
  );
};
